<template>
    <PromoBanner v-if="isUserInfoLoaded && shouldShowAddGameBanner"
                 :image="{src: AddGameImg, alt: t('add_game_alt')}"
                 :description="t('add_game_desc')"
                 hasCloseBtn
                 @closePromo="promoStore.hideAddGameBanner"
                 direction="row"
                 :link="{to: DEVELOPERS_LANDPAGE_PATH, target: '_blank'}"
                 :title="t('add_game_title')" textAlign="left">
    </PromoBanner>
</template>
<script setup lang="ts">
import AddGameImg from '@/components_new/PromoItems/images/add-game.png'
import PromoBanner from '@/components_new/PromoItems/PromoBanner.vue'
import { useI18n } from 'vue-i18n'
import { promoItemsTranslation } from '@/components_new/PromoItems/utils'
import { DEVELOPERS_LANDPAGE_PATH } from '@/constants/general'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'
import { usePromoStore } from '@/components_new/PromoItems/store'

const { t } = useI18n({
    messages: {
        en: {
            ...promoItemsTranslation.en,
        },
    },
})

const userStore = useUserStore()
const promoStore = usePromoStore()
const { isUserInfoLoaded } = storeToRefs(userStore)
const { shouldShowAddGameBanner } = storeToRefs(promoStore)
</script>
