<template>
    <component
        :is="props.target ? 'a' : RouterLink"
        :to="props.to"
        :href="props.to"
        :target="props.target"
        :class="[
            $style.baseLink,
            props.disabled ? $style.disabled : '',
        ]"
        @click="emit('click', $event)"
    >
        <slot />
    </component>
</template>

<script lang="ts" setup>
import { RouteLocationRaw, RouterLink } from 'vue-router'

export interface BaseLinkProps {
  disabled?: boolean,
  to: string | RouteLocationRaw,
  target?: '_self' | '_blank',
}

const props = defineProps<BaseLinkProps>()

/* eslint-disable-next-line no-unused-vars */
const emit = defineEmits<{(e: 'click', value: Event): void}>()

</script>

<style module>
.baseLink.disabled,
.baseLink.disabled:hover,
.baseLink.disabled:focus-within {
  pointer-events: none;
  cursor: default;
}

</style>
