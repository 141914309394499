<template>
    <div :class="$style.avatar">
        <Typography :class="$style.placeholder" type="label" size="xs" :accent="true" :responsive="false">
            {{ avatarPlaceholder }}
        </Typography>
        <div v-if="props.avatar" :style="{backgroundImage: `url(${props.avatar})`}" :class="$style.img" />
    </div>
</template>

<script lang="ts" setup>
import {
    computed,
} from 'vue'
import Typography from '@/components_new/Typography.vue'

type UserAvatarProps = {
    avatar?: string,
    firstName?: string,
    lastName?: string,
}

const props = defineProps<UserAvatarProps>()

const avatarPlaceholder = computed(() => `${props.firstName?.[0]?.toUpperCase() || 'x'}${props.lastName?.[0]?.toUpperCase() || ''}`)

</script>
<style module>
.avatar {
    width: 24px;
    aspect-ratio: 1;
    position: relative;
    border-radius: 1000px;
    overflow: hidden;
}

.placeholder, .img {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholder {
    background: #29292E;
}

.img {
    background-size: contain;
}
</style>
