export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a problem"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide as much information as possible"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image of Bug"])}
      }
    }
  })
}
