<template>
    <div :class="[$style.container, { [$style.fullscreen]: isFullscreen }]">
        <div :class="$style.fullscreen_pannel">
            <Logo noLink />
            <div :class="$style.pannel_spacer" />
            <Button visual="outline" @click="toggleFullscreen()">
                {{ t('exit_fullscreen') }}
            </Button>
        </div>
        <div :class="$style.fullscreen_pannel_compact">
            <Icon name="close" :class="$style.fullscreen_pannel_compact_close" @click="toggleFullscreen()"/>
        </div>
        <div :class="$style.content">
            <template v-if="game">
                <template v-if="isGameStarted">
                    <RotateMessage :orientation="orientation">
                        <iframe
                            :src="exportGameURL"
                            :class="$style.iframe"
                            :title="game.title"
                            allow="fullscreen"
                        />
                    </RotateMessage>
                    <template v-if="!isFullscreen && platform !== 'desktop'">
                        <div :class="$style.paranja"/>
                        <div :class="$style.cover">
                            <Button size="l" width="full" visual="outline" color="white" @click="toggleFullscreen()">{{ t('continue_play')
                            }}</Button>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div :class="$style.background" :style="{ backgroundImage: `url(${game.preview})` }"></div>
                    <div :class="$style.cover">
                        <img :class="$style.preview" :src="game.preview" :alt="game.title" />
                        <div :class="$style.cover_buttons">
                            <template v-if="limitedAvailablity">
                                <Typography type="paragraph" size="m" accent :class="$style.limited_availability">
                                    {{ limitedAvailablity }}
                                </Typography>
                            </template>
                            <template v-else>
                                <template v-if="isUserInfoLoaded && !isAuthorized">
                                    <Button size="l" width="full" effect="bevel" color="golden" iconLeft="fennek_coin" @click="handlePlayAndEarn">
                                        {{ t('playAndEarn') }}
                                    </Button>
                                    <Button size="l" width="full" visual="outline" color="white" @click="handlePlay">
                                        {{ t('playNoAuth') }}
                                    </Button>
                                </template>
                                <template v-else>
                                    <Button size="l" width="full" effect="bevel" color="golden" @click="handlePlay">{{ t('play')
                                    }}</Button>
                                </template>
                            </template>
                        </div>
                    </div>
                </template>
            </template>
        </div>
        <div :class="$style.footer">
            <template v-if="props.game">
                <Typography is="div" type="label"  size="s" :accent="true" :class="$style.title">{{ props.game.title }}</Typography>
                <div :class="$style.pannel_spacer" />
                <Button :class="$style.report_button_wide" visual="text" color="carbon-400" iconLeft="report" @click="handleReport">
                    {{ t('report') }}
                </Button>
                <Button :class="$style.report_button_short"  visual="text" color="carbon-400" icon="report" @click="handleReport"/>
                <div style="position: relative;">
                    <Tooltip :content="t('link_copied')" :shown="showCopied">
                        <Button visual="text" color="carbon-400" iconLeft="share" @click="handleShare">{{t('share')}}</Button>
                    </Tooltip>
                </div>
                <template v-if="platform === 'desktop'">
                    <Button v-if="!isFullscreen" visual="text" color="carbon-400" iconLeft="fullscreen" @click="toggleFullscreen()">
                        {{ t('fullscreen') }}
                    </Button>
                    <Button v-else visual="text" color="carbon-400" iconLeft="collapse" @click="toggleFullscreen()">
                        {{ t('fullscreen') }}
                    </Button>
                </template>
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {
    computed,
    onMounted,
    onUnmounted,
    ref,
    watch,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import Button from '@/components_new/Button.vue'
import Logo from '@/components_new/Logo/Logo.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import { GameType, PlatformType } from '@/types'
import { useUserStore } from '@/store/user-store/user-store'
import { useAppStore } from '@/store/app-store'
import { useLayoutStore } from '@/store/layout-store'
import Typography from '@/components_new/Typography.vue'
import Tooltip from '@/components_new/Tooltip.vue'
import { useLoggerStore } from '@/store/logger-store'
import RotateMessage from './RotateMessage/RotateMessage.vue'

const { t } = useI18n()

const router = useRouter()
const route = useRoute()

const userStore = useUserStore()
const { platform } = useAppStore()
const loggerStore = useLoggerStore()

const { isAuthorized, isUserInfoLoaded } = storeToRefs(userStore)

interface GameDetailsModalProps {
  game?: GameType
  isGameStarted: boolean
}

const props = defineProps<GameDetailsModalProps>()

const exportGameURL = computed(() => {
    if (!props.game) {
        return ''
    }
    return router.resolve({
        name: 'export-game-page',
        params: {
            game: props.game.hru,
        },
        query: route.query,
    }).href
})

const orientation = computed(() => {
    const result = [] as ('horizontal' | 'vertical')[]
    if (props.game?.vertical) {
        result.push('vertical')
    }
    if (props.game?.horizontal) {
        result.push('horizontal')
    }
    return result
})

const limitedAvailablity = computed(() => {
    const { game } = props
    if (!game) {
        return false
    }
    const availablePlatforms: PlatformType[] = []
    if (game.is_desktop) {
        availablePlatforms.push('desktop')
    }
    if (game.is_android) {
        availablePlatforms.push('android')
    }
    if (game.is_ios) {
        availablePlatforms.push('ios')
    }

    if (availablePlatforms.includes(platform)) {
        return false
    }

    if (availablePlatforms.length === 1) {
        return t('limitedAvailablity1', { platform: t(availablePlatforms[0]) })
    }
    return t('limitedAvailablityMany', {
        platfroms: availablePlatforms.slice(0, -1).map((p) => t(p)).join(', '),
        lastPlatform: t(availablePlatforms[availablePlatforms.length - 1]),
    })
})

const isFullscreen = ref<boolean>(false)

const layoutStore = useLayoutStore()
const showCopied = ref(false)

watch(() => isFullscreen.value, (value) => {
    layoutStore.setFullscreen(value)
})

async function toggleFullscreen(forceFullscreen?: boolean) {
    if (typeof forceFullscreen === 'boolean') {
        isFullscreen.value = forceFullscreen
    } else {
        isFullscreen.value = !isFullscreen.value
    }
    if (document.fullscreenEnabled) {
        if (isFullscreen.value) {
            try {
                await document.body.requestFullscreen()
            } catch (e) {
                // iOS or non-gesture call fullscreen
            }
        } else {
            try {
                await document.exitFullscreen()
            } catch (e) {
                // we might be not in fullscreen mode
            }
        }
    }
}

/* eslint-disable no-unused-vars */
/* eslint-disable no-spaced-func */
/* eslint-disable func-call-spacing */
const emit = defineEmits<{
  (e: 'startPlayingGame' | 'openReportModal'): void
}>()

function startGame() {
    if (platform !== 'desktop') {
        toggleFullscreen(true)
    }
    emit('startPlayingGame')
}

function handlePlay() {
    loggerStore.logEvent({
        event: 'custom_event',
        eventName: 'play',
        label: 'game',
        action: 'click',
    })
    startGame()
}

function handlePlayAndEarn() {
    loggerStore.logEvent({
        event: 'custom_event',
        eventName: 'sign-in',
        label: 'play-and-earn-game',
        action: 'click',
    })
    layoutStore.setSignInModalOpened(true)
}

function handleReport() {
    emit('openReportModal')
}

function handleShare() {
    if (!props.game) {
        return
    }
    loggerStore.logEvent({
        event: 'custom_event',
        eventName: 'share',
        label: 'game',
        action: 'click',
    })
    const data = {
        title: props.game?.title,
        text: props.game?.description,
        url: window.location.href, // TODO add query param to indicate sharing
    }
    if (platform !== 'desktop' && navigator.canShare && navigator.canShare(data)) {
        navigator.share(data)
    } else {
        navigator.clipboard.writeText(data.url).then(
            () => {
                showCopied.value = true
                setTimeout(() => {
                    showCopied.value = false
                }, 3000)
            },
            () => {
                console.error('Failed to copy text')
            },
        )
    }
}

const onFullscreenChange = () => {
    isFullscreen.value = document.fullscreenElement !== null
}
onMounted(() => {
    if (document.fullscreenEnabled) {
        document.addEventListener('fullscreenchange', onFullscreenChange)
    }

    if (route.query.autoplay && props.game) {
        startGame()
    }
})

watch(() => props.game, () => {
    if (route.query.autoplay && props.game) {
        startGame()
    }
})

onUnmounted(() => {
    document.removeEventListener('fullscreenchange', onFullscreenChange)
    layoutStore.setFullscreen(false)
})

</script>

<style module>
.container {
    background: #000;
    --cotainer-border-radius: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.content {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    border-radius: var(--cotainer-border-radius) var(--cotainer-border-radius) 0 0;
}

.content > * {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    height: 100%;
    position: relative;
}

.background {
    background-size: cover;
    background-position: center;
    transform: scale(1.5);
    filter: blur(30px) brightness(0.4);
    pointer-events: none;
}

.cover {
    width: min-content;
    justify-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 24px;
}

.paranja {
    background: rgba(0, 0, 0, 0.5);
}

.preview {
    width: 220px;
    height: 132px;
    object-fit: cover;
    border-radius: 12px;
    overflow: hidden;
}

.cover_buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.limited_availability {
    text-align: center;
    width: 100%;
}

.iframe {
    object-fit: contain;
}

.footer {
    background-color: #17171a;
    padding: 12px 16px;
    height: calc(32px + 12px * 2);
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 var(--cotainer-border-radius) var(--cotainer-border-radius);
}

.title {
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pannel_spacer {
    flex-grow: 1;
}

.report_button_short {
    display: block;
}
.report_button_wide {
    display: none;
}

.fullscreen {
    --cotainer-border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100dvh;
    z-index: 1;
}

.fullscreen * {
    touch-action: manipulation;
}

.fullscreen .content {
    flex-grow: 1;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.fullscreen_pannel {
    display: none;
    align-items: center;
    background: #000;
    padding: 0 12px;
    height: 48px;
}

.fullscreen_pannel_compact {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #29292E;
    border-radius: 0 0 16px 0;
    padding: 16px 12px;
}

.fullscreen_pannel_compact_close {
    width: 16px;
    height: 16px;
}

@media (--mobile) {
    .container {
        --cotainer-border-radius: 0;
        margin: calc(var(--global-page-top-padding) * -1) calc(var(--global-padding-horizontal) * -1) 0;
    }

    .container.fullscreen {
        margin: 0;
    }

    .content {
        height: 344px;
    }

    .cover_buttons {
        flex-direction: column;
        gap: 12px;
    }

    .fullscreen .fullscreen_pannel {
        display: flex;
        padding-top: env(safe-area-inset-top);
    }

    .fullscreen .footer {
        display: none;
    }
}

@media (--mobile-landscape) {

    .fullscreen .fullscreen_pannel {
        display: none;
    }

    .fullscreen .fullscreen_pannel_compact {
        display: flex;
    }
}

@media (--tablet-plus) {
    .content {
        aspect-ratio: 16 / 9;
        min-height: 464px;
        height: auto;
    }
}

@media (--desktop-small-plus) {
    .preview {
        width: 448px;
        height: 272px;
    }

    .cover_buttons {
        flex-direction: row;
        gap: 16px;
    }

    .report_button_short {
        display: none;
    }
    .report_button_wide {
        display: block;
    }
}
</style>
<i18n lang="json">{
    "en": {
        "play": "Play now",
        "playNoAuth": "Just Play",
        "playAndEarn": "Play & Earn",
        "continue_play": "Continue play",
        "like": "Like",
        "share": "Share",
        "link_copied": "Link copied!",
        "report": "Report",
        "fullscreen": "Fullscreen",
        "exit_fullscreen": "Exit game",
        "limitedAvailablity1": "Available only on {platform}",
        "limitedAvailablityMany": "Available only on {platfroms} and\u00A0{lastPlatform}",
        "desktop": "Desktop",
        "android": "Android",
        "ios": "iOS",
    }
}</i18n>
