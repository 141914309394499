<template>
    <img v-if="typeof icon === 'string'" :src="icon" :alt="altText" :class="[$style.icon, $style.img]" />
    <component v-else :class="$style.icon" :is="icon" role="img" :aria-label="altText" />
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { IconNameType, icons } from '@/components_new/Icon/index'

export type { IconNameType }

const props = defineProps<{
    name: IconNameType;
    alt?: string;
}>()

const icon = computed(() => icons[props.name])

const altText = computed(() => props.alt || `${props.name} icon`)

</script>
<style module>
.icon {
    display: inline-block;
}

.img {
    width: var(--icon-width, 24px);
    height: var(--icon-height, 24px);
    object-fit: contain;
}
</style>
