<!-- eslint-disable-next-line vue/valid-template-root -->
<template>
</template>
<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useGamePageStore } from '@/pages/Games/game-store'
import { storeToRefs } from 'pinia'
import {
    gameTranslation, GenerateGameHeadParams, getMetaURL, getPlatforms,
} from '@/pages/Games/utils'

const { t } = useI18n({
    messages: {
        en: {
            ...gameTranslation.en,
            title: 'Play {title} Online for Free | Playgama 🎮',
            alt_image: 'Preview game {title}',
            description: 'Enjoy {title} on Playgama! Dive into an exciting experience with no downloads or registrations required. ✨ Play now on desktop, tablet, and mobile. 🕹️',

        },
    },
})
const gamePageStore = useGamePageStore()
const { game } = storeToRefs(gamePageStore)
const pageMeta = computed(() => generateHead({ game }))
const metaDescription = computed(() => t('description', { title: game.value?.title }))
const metaTitle = computed(() => t('title', { title: game.value?.title }))
const altImg = computed(() => t('alt_image', { title: game.value?.title }))

function generateHead({
    game,
}: GenerateGameHeadParams) {
    if (!game) {
        return {}
    }
    return {
        title: () => metaTitle.value,
        meta: [
            { name: 'description', content: () => metaDescription.value },
            { property: 'og:title', content: () => metaTitle.value },
            { property: 'og:description', content: () => metaDescription.value },
            { property: 'og:url', content: () => getMetaURL(game.value?.hru) },
            { property: 'og:image', content: () => game.value?.preview },
            { property: 'og:image:alt', content: () => altImg.value },
            { property: 'game:platform', content: () => getPlatforms(game.value).map(t).join(', ') },
            { name: 'twitter:title', content: () => metaTitle.value },
            { name: 'twitter:description', content: () => metaDescription.value },
            { name: 'twitter:image', content: () => game.value?.preview },
            { name: 'twitter:image:alt', content: () => altImg.value },
        ],
        link: [
            {
                rel: 'canonical',
                href: () => getMetaURL(game.value?.hru),
            },
        ],
    }
}
useHead(pageMeta)

</script>
