<template>
    <ModalContainer @closeModal=onCloseModal
                    :title="t('title')"
                    :description="t('description')"
                    :image="SignInImg"
                    :alt="t('alt')"
                    :isOpened="signInModalOpened"
                    :class="$style.dialog_sing_in">
        <template v-slot:actions>
            <GoogleAuth :class="$style.button_sing_in"/>
        </template>
    </ModalContainer>
</template>
<script setup lang="ts">

import ModalContainer from '@/components_new/ModalItems/ModalContainer.vue'
import SignInImg from '@/components_new/ModalItems/images/sign-in.png'
import { useI18n } from 'vue-i18n'
import { useLayoutStore } from '@/store/layout-store'
import { storeToRefs } from 'pinia'
import GoogleAuth from '@/components_new/GoogleAuth.vue'

const { t } = useI18n()
const layoutStore = useLayoutStore()

const { signInModalOpened } = storeToRefs(layoutStore)

function onCloseModal() {
    layoutStore.setSignInModalOpened(false)
}

</script>
<style module>
.dialog_sing_in {
  --dialog-width: 420px;
}

.button_sing_in {
  margin-bottom: 24px;
  margin-top: 8px;
}

@media (--mobile) {
  .dialog_sing_in {
    --dialog-width: 357px;
  }
}

</style>
<i18n lang="json">{
  "en": {
    "title": "Join us on Playgama",
    "description": "Play games, rack up coins, and score real-worlds rewards!",
    "alt": "Join us on Playgama"
  }
}</i18n>
