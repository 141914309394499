<template>
    <div>
        <Typography
            is="div"
            type="paragraph"
            size="xs"
            :class="[$style.seo, {[$style.seo_expanded]: seoExpanded}, $style[props.color]]"
            ref="seoRef"
        >
            <!-- key here helps top re-mount this element and fix glitch on Safari -->
            <p v-html="props.content" :key="seoExpanded ? 'expanded' : 'collapsed'"/>
        </Typography>
        <Button
            v-if="!seoExpanded"
            :class="$style.seo_button"
            color="carbon-400"
            @click="onExpandTextClick"
        >
            {{ t('show_full') }}
        </Button>
    </div>
</template>
<script lang="ts" setup>
import Typography from '@/components_new/Typography.vue'
import Button from '@/components_new/Button.vue'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
    defineProps< { content: string, minLinesToShow?: number, color?: 'white-80' | 'white-60' }>(),
    { minLinesToShow: 5, color: 'white-60' },
)
const seoRef = ref<InstanceType<typeof Typography> | null>(null)
const seoExpanded = ref(false)
const defaultLineHeight = 16

const { t } = useI18n()

function onExpandTextClick() {
    seoExpanded.value = true
}

onMounted(() => {
    if (!seoRef.value?.$el) return
    const seoClientHeight = seoRef.value.$el.clientHeight
    const seoScrollHeight = seoRef.value.$el.scrollHeight
    let seoLineHeight = parseInt(window.getComputedStyle(seoRef.value.$el).lineHeight, 10)
    if (Number.isNaN(seoLineHeight)) {
        seoLineHeight = defaultLineHeight
    }
    const diff = seoScrollHeight - seoClientHeight
    if (diff / seoLineHeight > props.minLinesToShow) {
        seoExpanded.value = false
    } else {
        seoExpanded.value = true
    }
})
</script>
<style module>

.seo {
  --color: var(--new-white-60);
  color: var(--color);
  display: grid;
  grid-template-rows: repeat(4, 1.7rem); /*approx. amount of rows based on line heights and margins for h* */
  overflow: hidden;
}

.seo.white-60 {
  --color: var(--new-white-60);
}

.seo.white-80 {
  --color: var(--new-white-80);
}

.seo h2,
.seo h3,
.seo h4,
.seo h5,
.seo h6 {
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: var(--new-font-size-label-l);
  font-weight: var(--new-font-weight-accent);
  line-height: var(--new-line-height-label);
}

.seo h2:first-child,
.seo h3:first-child,
.seo h4:first-child,
.seo h5:first-child,
.seo h6:first-child {
  margin-top: 0;
}

.seo_expanded {
  display: block;
  overflow: visible;
}

.seo ul {
  list-style-type: disc;
  list-style-position: inside;
}

.seo_button {
  margin-top: 16px;
}

@media (--mobile) {
  .seo {
    grid-template-rows: repeat(8, 1.7rem);
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "show_full": "Show all description"
  }
}
</i18n>
