import { RewardedBanner } from '@/modules/adv/rewarded-banner'
import {
    AdProviderStatus,
    AdvService, OutOfPageAdType,
} from '../adv'

export class DebugAdsProvider implements AdvService {
    readonly serviceStatus: Promise<AdProviderStatus>

    constructor() {
        this.serviceStatus = Promise.resolve(AdProviderStatus.online)
    }

    // eslint-disable-next-line class-methods-use-this
    prepareOutOfPageAd(type: OutOfPageAdType): Promise<RewardedBanner> {
        return new Promise((res) => {
            const banner = new RewardedBanner({})

            setTimeout(() => {
                res(banner)
            }, 100)

            setTimeout(() => {
                banner.triggerReady(() => {
                    setTimeout(() => {
                        // todo: show banner
                        banner.triggerRendered()
                    }, 800)
                    setTimeout(() => {
                        banner.triggerViewable()
                    }, 1400)
                    if (type === 'rewarded') {
                        setTimeout(() => {
                            banner.triggerRewarded({})
                        }, 3500)
                    }
                    setTimeout(() => {
                        // todo: close banner
                        banner.triggerClosed()
                    }, 4000)
                })
            }, 400)
        })
    }

    // eslint-disable-next-line class-methods-use-this
    requestPageAd() {
        return Promise.reject()
    }

    updateTargeting() {}
}
