<template>
    <Layout>
        <LimitedWidthLayout>
            <HeroImage imageType="fennek-butterfly" />
            <Typography
                is="h1"
                size="l"
            >
                {{ t('title') }}
            </Typography>

            <ContentSection is="section">
                <Socials />
            </ContentSection>
            <ContentSection is="section">
                <div :class="$style.ctaContainer">
                    <Typography
                        is="h2"
                        size="m"
                    >
                        {{ t('cta') }}
                    </Typography>
                    <Typography
                        is="p"
                        size="m"
                        :class="$style.ctaSubtitle"
                    >
                        {{ t('ctaSubtitle') }}
                    </Typography>
                </div>
                <ContactForm
                    v-model="formData"
                    :submitStatus="submitStatus"
                    @formReset="resetForm"
                    @submit="handleSubmit"
                />
            </ContentSection>
        </LimitedWidthLayout>
    </Layout>
</template>
<script setup lang="ts">
import ContactForm from '@/components_new/ContactForm/ContactForm.vue'
import { useContactForm } from '@/components_new/ContactForm/use-contact-form'
import HeroImage from '@/components_new/HeroImage/HeroImage.vue'
import Typography from '@/components_new/Typography.vue'
import ContentSection from '@/pages/ContactUs/components/ContentSection.vue'
import LimitedWidthLayout from '@/layouts/LimitedWidthLayout.vue'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import Socials from '@/pages/ContactUs/components/Socials.vue'
import { useCategoriesStore } from '@/store/categories-store'
import { useHead } from '@unhead/vue'
import { onMounted, onServerPrefetch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const categoriesStore = useCategoriesStore()

const pageMeta = {
    title: `${t('metaTitle')} 📞`,
    meta: [
        {
            name: 'description',
            content: t('metaDescription'),
        },
    ],
}

useHead(pageMeta)

const {
    formData,
    submitStatus,
    resetForm,
    handleSubmit,
} = useContactForm()

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
})

onMounted(async () => {
    await categoriesStore.setMainData()
})
</script>

<style module>
.ctaContainer {
    display: flex;
    flex-direction: column;
    gap: var(--gap-medium);
    margin-bottom: var(--gap-large);
}

.ctaSubtitle {
    color: var(--new-white-60);
}
</style>

<i18n lang="json">
{
    "en": {
        "metaTitle": "Contact Us | Playgama",
        "metaDescription": "Get in touch with the Playgama team for any inquiries, support, or feedback. We're here to help!",
        "title": "Contact us",
        "cta": "Ask a question",
        "ctaSubtitle": "Please provide as much information as possible"
    }
}
</i18n>
