<template>
    <div :class="$style.heroImageContainer">
        <Image
            withFadeInOnLoad
            ref="imageRef"
            :class="$style.img"
            :src="chosenImage.src"
            :alt="chosenImage.alt"
            @loaded="isImageLoaded = true"
        />
    </div>
</template>
<script setup lang="ts">
import {
    computed, onMounted, onUnmounted, ref,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useMediaQuery } from '@vueuse/core'
import fennekButterflyImg from '@/components_new/HeroImage/images/fennek-butterfly.png'
import personInAFlowerField from '@/components_new/HeroImage/images/person-in-a-flower-field.png'
import pingPongRocket from '@/components_new/HeroImage/images/ping-pong-rocket.png'
import boringDocsDisk from '@/components_new/HeroImage/images/boring-docs-disk.png'
import gemsOnPillow from '@/components_new/HeroImage/images/gems-on-pillow.png'
import halvedApple from '@/components_new/HeroImage/images/halved-apple.png'
import Image from '@/components_new/Image/Image.vue'

const { t } = useI18n()

const PARALLAX_OPACITY_PX = 300
// WARNING, THIS MUST BE COMPUTED (useI18n sometimes loads incorrectly with constants),
// but for consistency (FOR NOW) we made it a constant
const TYPE_TO_IMAGES_DATA = {
    'fennek-butterfly': { src: fennekButterflyImg, alt: t('fennek-butterfly') },
    'person-in-a-flower-field': { src: personInAFlowerField, alt: t('fennek-butterfly') },
    'ping-pong-rocket': { src: pingPongRocket, alt: t('ping-pong-rocket') },
    'boring-docs-disk': { src: boringDocsDisk, alt: t('boring-docs-disk') },
    'gems-on-pillow': { src: gemsOnPillow, alt: t('gems-on-pillow') },
    'halved-apple': { src: halvedApple, alt: t('halved-apple') },
}
type ImageType = keyof typeof TYPE_TO_IMAGES_DATA;

const props = defineProps<{
  imageType: ImageType
}>()

const isDesktop = useMediaQuery('(min-width: 960px)')

const imageRef = ref<InstanceType<typeof Image>>()
const isImageLoaded = ref(false)

const handleScrollParallax = () => {
    requestAnimationFrame(() => {
        const imageEl = imageRef.value?.$el
        if (!imageEl) {
            return
        }
        const scrollY = Math.max(window.scrollY, 0)
        // if page already had scroll, disable parallax
        const wasPreScrolled = scrollY > 50 && !imageEl?.style.transform
        if (wasPreScrolled) {
            window.removeEventListener('scroll', handleScrollParallax)
            return
        }
        imageEl.style.transform = `translate3d(0px, ${scrollY * 0.3}px, 0px)`
        if (isImageLoaded.value) {
            imageEl.style.opacity = `${1 - Math.min(scrollY, PARALLAX_OPACITY_PX) / PARALLAX_OPACITY_PX}`
        }
    })
}

const chosenImage = computed(() => TYPE_TO_IMAGES_DATA[props.imageType])

onMounted(() => {
    if (!isDesktop.value) {
        return
    }
    window.addEventListener('scroll', handleScrollParallax, { passive: true })
})
onUnmounted(() => {
    window.removeEventListener('scroll', handleScrollParallax)
})
</script>

<style module>
.heroImageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
}

.heroImageContainer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to bottom, transparent 80%, black);
}

.img {
  object-fit: cover;
  margin: auto;
  object-position: center;
  max-height: 260px;
  position: absolute;
  top: 0;
  bottom: 0;
  will-change: transform, opacity;
  user-select: none;
}
</style>

<i18n lang="json">
{
  "en": {
    "fennek-butterfly": "Fennek playing with a butterfly",
    "person-in-a-flower-field": "Person in a flower-field",
    "ping-pong-rocket": "Ping-pong rocket",
    "boring-docs-disk": "Disk with caption: Boring docs",
    "gems-on-pillow": "Gems on top of the pillow",
    "halved-apple": "Halved apple"
  }
}
</i18n>
