export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "privacyMetaTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Privacy Policy"]), _normalize(["Playgama"])])},
        "privacyMetaDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about Playgama's commitment to protecting your privacy and personal information. Read our privacy policy."])},
        "termsOfUseMetaTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Terms of Use"]), _normalize(["Playgama"])])},
        "termsOfUsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review the terms and conditions for using Playgama's services. Understand your rights and responsibilities."])},
        "licenseMetaTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["License Agreement"]), _normalize(["Playgama"])])},
        "licenseMetaDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read the license agreement for using Playgama's games and services. Ensure compliance and understanding."])},
        "takedownNoticeTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Takedown Notice"]), _normalize(["Playgama"])])},
        "takedownNoticeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about Playgama's commitment to fostering secure, transparent and legally compliant environment for all our developers, users and partners."])}
      }
    }
  })
}
