<template>
    <Transition
        :enter-active-class="$style.transition_enter_active"
        :enter-from-class="$style.transition_enter_from"
        :leave-active-class="$style.transition_leave_active"
        :leave-to-class="$style.transition_leave_to"
        @before-enter="onBeforeEnter"
        @after-leave="onAfterLeave"
        appear
    >
        <!-- eslint-disable-next-line vuejs-accessibility/no-autofocus vuejs-accessibility/click-events-have-key-events -->
        <dialog autofocus
                @click="outsideClick"
                v-if="props.isOpened"
                :ref="dialogOnMount"
                @close="requestCloseModal"
                :class="$style.dialog"

        >
            <Button @click="requestCloseModal" icon="close" color="carbon" visual="ghost" :class="$style.close" v-if="props.hasCloseBtn" />
            <div :class="$style.dialog_wrap">
                <div :class="$style.dialog_content">
                    <img :class="$style.dialog_image" :src="props.image" :alt="props.alt" />
                    <Typography type="header" size="s" :responsive="false">{{props.title}}</Typography>
                    <Typography :class="$style.dialog_description" is="p" size="s" :responsive="false">{{props.description}}</Typography>
                </div>
                <slot name="custom-content"></slot>
                <div :class="$style.dialog_actions" v-if="$slots.actions">
                    <slot name="actions"></slot>
                </div>
            </div>
        </dialog>
    </Transition>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import Typography from '@/components_new/Typography.vue'
import Button from '@/components_new/Button.vue'

interface DialogProps {
    title: string
    description: string
    image: string
    alt: string
    isOpened: boolean
    withOutsideClickClose?: boolean
    hasCloseBtn?: boolean
}

const props = withDefaults(defineProps<DialogProps>(), {
    hasCloseBtn: true,
    withOutsideClickClose: true,
})

const emit = defineEmits<{ closeModal: [] }>()

function requestCloseModal(e: Event) {
    e.preventDefault() // do not close dialog immediately, wait until the isOpened props will be updated
    emit('closeModal')
}
function outsideClick(e: MouseEvent) {
    if (props.withOutsideClickClose && e.target === e.currentTarget) {
        requestCloseModal(e)
    }
}

const dialogOnMount = (el: unknown | null) => (el as HTMLDialogElement)?.showModal()

const scrollTop = ref(0)
function onBeforeEnter() {
    scrollTop.value = document.documentElement.scrollTop
    requestAnimationFrame(() => {
        document.body.scrollTop = scrollTop.value
        // ios safari has bug with bottom navigation pannel.
        // Sometimes it causes dialog element
        // to be shifted up after applying "overflow: hidden" on body.
        // Solution: set random positive scroll after repaint
        document.documentElement.scrollTop = 1
    })
}

function onAfterLeave() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = scrollTop.value
}

</script>
<style module>

.transition_enter_active,
.transition_enter_active::backdrop {
  pointer-events: none;
  transition: all .4s cubic-bezier(0.17, 0.7, 0.17, 1);
}

.transition_leave_active,
.transition_leave_active::backdrop {
  pointer-events: none;
  transition: all .4s cubic-bezier(0.17, 0.7, 0.17, 1);
}

.dialog::backdrop {
  background-color: #000;
  opacity: 0.5;
}

.transition_enter_from::backdrop,
.transition_leave_to::backdrop {
  opacity: 0;
}

.dialog:focus-visible {
  outline-color: #C0FF47;
}

.dialog_wrap {
  display: flex;
  padding: 16px 12px;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  background-color: #17171A;
  margin: auto;
}
.dialog_image {
  user-select: none;
  width: 160px;
  height: 160px;
  display: block;
  margin-bottom: 8px;
}

.dialog_content {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.dialog_description {
  color: rgba(255, 255, 255, 0.60);
  padding: 0 8px;
}

.dialog_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-top: 16px;
}

.close {
  position: absolute;
  top: 2px;
  right: 2px;
}

@media (--mobile) {
  .transition_enter_from,
  .transition_leave_to {
    transform: translateY(100%);
  }

  .dialog {
    margin: auto 0 0 0;
    max-width: 100%;
    width: 100%;
  }

  .dialog_wrap {
    border-radius: 18px 18px 0 0;
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
  }
}

@media (--mobile-landscape), (--tablet-plus) {
  .transition_enter_from,
  .transition_leave_to
  {
    transform: translateY(600px);
    opacity: 0;
  }

  .dialog {
    margin: auto;
    max-width: min(var(--dialog-width, 380px), calc(100% - 20px))
  }

  .dialog_wrap {
    border-radius: 18px;
  }
}

</style>
