import {
    createMemoryHistory, createRouter, createWebHistory, RouteRecordRaw,
} from 'vue-router'
import { nonAuthorizedOnlyGuard } from './guards'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        component: () => import('@/InitWrapper.vue'),
        children: [
            {
                path: '',
                name: 'main',
                component: () => import('@/pages/Homepage/Homepage.vue'),
            },
            {
                path: 'game/:game',
                name: 'game-page',
                component: () => import('@/pages/Games/GameRouteWrapper.vue'),
            },
            {
                path: 'export/game/:game',
                name: 'export-game-page',
                component: () => import('@/pages/Games/ExportGame.vue'),
                meta: {
                    manuallyHandledErrors: true,
                },
            },
            {
                path: 'category/:category',
                name: 'category',
                component: () => import('@/pages/Category/CategoryPage.vue'),
                beforeEnter: (to) => {
                    const { page, ...queries } = to.query
                    if (page === '1') {
                        return { path: to.path, query: queries, replace: true }
                    }
                    return undefined
                },
            },
            {
                path: 'publishers',
                name: 'publishers',
                component: () => import('@/pages/old-landings/publishers/Publishers.vue'),
            },
            {
                path: 'advertisers',
                name: 'advertisers',
                component: () => import('@/pages/old-landings/advertisers/Advertisers.vue'),
            },
            {
                path: 'confidential',
                name: 'confidential',
                component: () => import('@/pages/static/StaticPage.vue'),
            },
            {
                path: 'termsofuse',
                name: 'termsofuse',
                component: () => import('@/pages/static/StaticPage.vue'),
            },
            {
                path: 'takedown-notice',
                name: 'takedown-notice',
                component: () => import('@/pages/static/StaticPage.vue'),
            },
            {
                path: 'license',
                name: 'license',
                component: () => import('@/pages/static/StaticPage.vue'),
            },
            {
                path: 'partners-api',
                name: 'partners-api',
                component: () => import('@/pages/static/StaticPage.vue'),
            },
            {
                path: 'contacts',
                name: 'contacts',
                component: () => import('@/pages/ContactUs/ContactUsPage.vue'),
            },
            {
                path: 'profile',
                name: 'profile',
                component: () => import('@/pages/UserProfile.vue'),
            },
            {
                path: 'shop',
                name: 'shop',
                component: () => import('@/pages/Shop.vue'),
            },
            {
                path: 'invite/:inviteCode',
                name: 'invite',
                beforeEnter: nonAuthorizedOnlyGuard,
                component: () => import('@/pages/Invite/Invite.vue'),
            },
            {
                path: '/:catchAll(.*)',
                component: () => import('@/pages/Error/NotFoundPage.vue'),
            },
        ],
    },
]

export default function getRouter(isServer: boolean) {
    return createRouter({
        routes,
        history: isServer ? createMemoryHistory() : createWebHistory(),
        scrollBehavior(to, from, savedPosition) {
            if (to.hash) {
                return {
                    el: to.hash,
                }
            }

            if (savedPosition) {
                return savedPosition
            }

            // special behavior on categories page
            if (to.name === 'category') {
                if (from.name === 'category') {
                    // do not scroll to top when changing only query params on the same category
                    if (to.params.category === from.params.category) {
                        return false
                    }
                } else if (to.query.page) {
                    // scroll to specific page on direct access
                    return {
                        el: `#page-${to.query.page}`,
                    }
                }
            }

            return { top: 0 }
        },
    })
}
