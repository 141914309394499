<template>
    <div v-if="task" :class="$style.card">
        <div :class="[$style.content_wrapper, {[$style.disabled]: isTaskClaimed}]">
            <div>
                <GameCard appearance="medium" targetLink="_blank" :game="task.game" responsive v-if="task.game && !isTaskClaimed" :preview="task.imgSrc" :class="$style.image"/>
                <template v-else>
                    <BaseLink :to="task.gameLink" target="_blank" v-if="task.gameLink && !isTaskClaimed" @click="onAction">
                        <CardContainer appearance="medium" :alt="task.imgAlt" responsive :src="task.imgSrc" :srcsetOn="task.srcsetOn" :class="$style.image" />
                    </BaseLink>
                    <CardContainer appearance="medium" :alt="task.imgAlt" responsive :src="task.imgSrc" :srcsetOn="task.srcsetOn" :class="$style.image" v-else/>
                </template>
            </div>
            <div :class="$style.fennecs">
                <Icon name="fennek_coin_small"/>
                <Typography type="header" size="s" active :responsive="false">{{ task.coins }}</Typography>
            </div>
            <Typography type="paragraph" size="s" :class="$style.description" :responsive="false">{{ task.description }}</Typography>
        </div>
        <Button v-if="task.status === 'ACTIVE'" size="m" :link="task.gameLink" @click="onAction">
            {{ task.buttonLabel }}
        </Button>
        <Button v-else size="m" color="golden" :disabled="isTaskClaimed" @click="onClaim">
            {{ isTaskClaimed ? t('claimed') : t('claim') }}
        </Button>
    </div>
</template>

<script setup lang="ts">
/* eslint-disable camelcase */

import {
    computed,
} from 'vue'

import {
    DailyTaskDto, GameTaskDto, RandomTaskDto, ReferralTaskDto, SimplifiedGame, TaskDto, TaskStatus, TaskType,
} from '@/types'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useCategoriesStore } from '@/store/categories-store'
import { useLoggerStore } from '@/store/logger-store'
import Button from '@/components_new/Button.vue'
import Typography from '@/components_new/Typography.vue'
import CardContainer from '@/components_new/CardContainer.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import randomGameImage from '@/components_new/TaskItems/images/random_game.png'
import dailyBonusImage from '@/components_new/TaskItems/images/daily_bonus.png'
import referralImage from '@/components_new/TaskItems/images/referral.png'
import GameCard from '@/components_new/GameCard.vue'
import { tasksTranslation } from '@/components_new/TaskItems/utils'
import BaseLink from '@/components_new/BaseLink.vue'

const loggerStore = useLoggerStore()
interface TaskCardProps {
  task?: TaskDto,
}

type TaskCard = {
  id: string
  status: TaskStatus
  coins: number
  imgSrc: string,
  imgAlt: string,
  description: string,
  buttonLabel: string,
  gameLink: string,
  srcsetOn: boolean,
  game: SimplifiedGame
  type: TaskType
  min: number
}

const props = defineProps<TaskCardProps>()
/* eslint-disable no-unused-vars */
const emit = defineEmits<{(e: 'claimTask', taskId: string): void }>()
const { t } = useI18n({ messages: tasksTranslation })

const categoriesStore = useCategoriesStore()

const {
    randomGame,
} = storeToRefs(categoriesStore)

function getReferralCard(task: ReferralTaskDto): TaskCard {
    const {
        status, coins, task_id, type, settings: { invitations_not_claimed },
    } = task
    const coinsPerFriend = invitations_not_claimed > 1 ? Math.floor(coins / invitations_not_claimed) : coins
    const description = invitations_not_claimed > 1 ? t('collect_invite_many', { friends: invitations_not_claimed, coins: coinsPerFriend }) : t('collect_invite', { coins: coinsPerFriend })
    return {
        status,
        coins,
        id: task_id,
        type,
        srcsetOn: false,
        imgSrc: referralImage,
        imgAlt: t('collect_invite_alt'),
        buttonLabel: t('claim'),
        description,
    }
}

function getDailyBonusCard(card: DailyTaskDto): TaskCard {
    const {
        status, coins, task_id, type,
    } = card
    return {
        status,
        coins,
        id: task_id,
        type,
        srcsetOn: false,
        imgSrc: dailyBonusImage,
        imgAlt: t('daily_bonus_alt'),
        buttonLabel: t('claim'),
        description: t('daily_bonus_description'),
    }
}

function getRandomGameCard(card: RandomTaskDto): TaskCard {
    const {
        status, coins, settings, task_id, type,
    } = card
    const min = Math.ceil(settings.seconds_to_play / 60)
    return {
        status,
        type,
        id: task_id,
        coins,
        srcsetOn: false,
        imgSrc: randomGameImage,
        gameLink: `/game/${randomGame.value?.hru}`,
        buttonLabel: t('play'),
        description: t('random_game_description', { min }),
    }
}

function getGameCard(card: GameTaskDto): TaskCard {
    const {
        status, coins, settings: { game, seconds_to_play }, task_id, type,
    } = card
    const min = Math.ceil(seconds_to_play / 60)
    return {
        status,
        type,
        coins,
        id: task_id,
        srcsetOn: true,
        imgAlt: game.title,
        gameLink: `/game/${game?.hru}`,
        game,
        imgSrc: game.preview,
        description: game.title,
        buttonLabel: t('button_label', { min }),
        min,
    }
}

const task = computed(() => {
    if (props.task.type === 'VISIT_EVERYDAY') {
        return getDailyBonusCard(props.task)
    }

    if (props.task.type === 'RANDOM_GAME') {
        return getRandomGameCard(props.task)
    }

    if (props.task.type === 'PLAY_GAME') {
        return getGameCard(props.task)
    }
    return getReferralCard(props.task)
})

const isTaskClaimed = computed(() => task.value.status === 'CLAIMED')

function onClaim() {
    emit('claimTask', task.value.id)
    loggerStore.logEvent({
        event: 'custom_event',
        eventName: 'claim',
        label: 'task',
        action: 'click',
    })
}

function onAction() {
    if (task.value.type === 'RANDOM_GAME') {
        categoriesStore.updateRandomGame()
    }
    loggerStore.logEvent({
        event: 'custom_event',
        eventName: 'do-task',
        label: 'task',
        action: 'click',
    })
}

</script>

<style module>

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
}

.fennecs {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  margin-top: 8px;
}

.description {
  color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content_wrapper {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
}

.disabled {
  opacity: 0.4;
  filter: grayscale(1);
}
</style>
