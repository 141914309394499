<template>
    <div :class="$style.container">
        <template v-if="props.link">
            <Typography v-if="props.title" is="h2" size="xs" :responsive="false" :class="$style.title">
                <BaseLink :to="props.link" tabindex="-1" color="white">
                    {{ props.title }}
                </BaseLink>
                <Button
                    icon="chevron_r"
                    color="carbon-400"
                    size="s"
                    :link="props.link"
                    :class="$style.title_button"
                />
            </Typography>
        </template>
        <Typography is="h2" size="xs" :responsive="false" v-else-if="props.title" :class="$style.title">
            {{ props.title }}
        </Typography>
        <div :class="$style.scroller_wrap">
            <div
                :class="{
                    [$style.scroller]: true,
                    [$style.scroller_empty]: props.dummy
                }"
                @scroll="onScroll" ref="scrollerRef"
            >
                <template
                    v-for="(item, index) in props.items"
                    :key="index"
                >
                    <slot
                        :item="item"
                        :index="index"
                        :className="$style.card"
                        :bigCardClassName="$style.card_big"
                    />
                </template>
                <Button
                    v-if="props.lastItemLink"
                    icon="chevron_r"
                    color="carbon-400"
                    size="l"
                    :link="props.lastItemLink"
                    :class="[$style.card, $style.card_next]"
                />
            </div>
            <button
                tabindex="-1"
                :class="[$style.arrow, leftArrowActive && $style.arrow_active, $style.arrow_left]"
                @click="onArrowLeftClick"
            >
                <Icon name="chevron_l"/>
            </button>
            <button
                tabindex="-1"
                :class="[$style.arrow, rightArrowActive && $style.arrow_active, $style.arrow_right]"
                @click="onArrowRightClick"
            >
                <Icon name="chevron_r"/>
            </button>
        </div>
    </div>
</template>
<script lang="ts" setup>
import {
    onMounted, ref, useCssModule,
} from 'vue'
import { useResizeObserver, useMutationObserver } from '@vueuse/core'
import Button from '@/components_new/Button.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import BaseLink from '@/components_new/BaseLink.vue'

interface GameCarouselProps {
    items: unknown[]
    dummy?: boolean,
    title?: string,
    link?: string,
    rows?: number,
    lastItemLink?: string,
}

const props = defineProps<GameCarouselProps>()
const classes = useCssModule()

const scrollerRef = ref<HTMLElement | null>(null)
const leftArrowActive = ref(false)
const rightArrowActive = ref(false)

function onScroll(e: { currentTarget: unknown | null }) {
    const scroller = e.currentTarget
    if (!(scroller instanceof HTMLElement)) return
    const notEmpty = !props.dummy
    leftArrowActive.value = notEmpty && scroller.scrollLeft > 0
    rightArrowActive.value = notEmpty && scroller.scrollLeft + scroller.clientWidth + 1 < scroller.scrollWidth
}

function getScrollStepSize() {
    // get the width of the second card (first card sometimes could be big one)
    const card = scrollerRef.value?.querySelector(`.${classes.card}:nth-child(2)`)
    if (!card) return 0

    const computedStyle = getComputedStyle(card)
    const cardWidth = parseInt(computedStyle.getPropertyValue('width'), 10)
    let cardsCount = parseInt(computedStyle.getPropertyValue('--count'), 10)

    // if there are more than one big card, we assume that the carousel is "big" and every card takes 2 regular slots
    const hasBigCards = scrollerRef.value?.querySelectorAll(`.${classes.card_big}`)
    if (hasBigCards && hasBigCards.length > 1) {
        cardsCount /= 2
    }
    const gap = parseInt(computedStyle.getPropertyValue('--gap'), 10)

    return cardsCount * cardWidth + (cardsCount - 1) * gap
}

function onArrowLeftClick() {
    const scroller = scrollerRef.value
    if (scroller) {
        const scrollSize = getScrollStepSize()
        scroller.scroll({
            left: Math.max(0, scroller.scrollLeft - scrollSize),
            behavior: 'smooth',
        })
    }
}
function onArrowRightClick() {
    const scroller = scrollerRef.value
    if (scroller) {
        const scrollSize = getScrollStepSize()
        scroller.scroll({
            left: Math.min(scroller.scrollWidth - scroller.clientWidth, scroller.scrollLeft + scrollSize),
            behavior: 'smooth',
        })
    }
}

useResizeObserver(scrollerRef, () => {
    if (scrollerRef.value) {
        onScroll({ currentTarget: scrollerRef.value })
    }
})

useMutationObserver(scrollerRef, () => {
    if (scrollerRef.value) {
        onScroll({ currentTarget: scrollerRef.value })
    }
}, {
    childList: true,
})

onMounted(() => {
    if (scrollerRef.value) {
        onScroll({ currentTarget: scrollerRef.value })
    }
})

</script>
<style module>
.container {
    --scroller-arrow-size: 40px;
}

.title {
    margin-bottom: 12px;
}

.title_button {
    margin-left: 8px;
    opacity: 0;
    transition: opacity .1s ease;
}

.container:hover .title_button,
.container:focus-within .title_button {
    opacity: 1;
}

@media (hover: none) {
    .title_button {
        opacity: 1;
    }
}

.scroller_wrap {
    position: relative;
    /* crop arrows when they are hidden */
    overflow: hidden;

    margin-left: calc(var(--global-wide-scroller-padding-left) * -1);
    margin-right: calc(var(--global-wide-scroller-padding-right) * -1);
}

.arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    width: var(--scroller-arrow-size);
    padding: 0 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    --transition-duration: .15s;
    --transition-fn: ease-in-out;
    transition:
        var(--transition-duration) var(--transition-fn) opacity,
        var(--transition-duration) var(--transition-fn) transform,
        0s var(--transition-fn) visibility var(--transition-duration);
}

.arrow_left {
    left: 0;
    transform: translateX(-10px);
    background: linear-gradient(to right, #0B0B0C, transparent);
}

.arrow_right {
    right: 0;
    transform: translateX(10px);
    background: linear-gradient(to left, #0B0B0C, transparent);
}

@media (--tablet-plus) {
  .arrow_active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
    transition:
        var(--transition-duration) var(--transition-fn) opacity,
        var(--transition-duration) var(--transition-fn) transform,
        0s var(--transition-fn) visibility;
  }
}

.scroller {
    overflow: auto;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;
    scroll-padding: var(--scroller-arrow-size);
    scrollbar-width: none;

    padding-left: var(--global-wide-scroller-padding-left);
    padding-right: var(--global-wide-scroller-padding-right);

    /* base-size вычисляется так, чтобы на экране было --count обычных карточек + половинка еще одна */
    /* но не меньше 160 и не больше 220px */
    --base-size: clamp(
        160px,
        calc((100% - var(--gap) * var(--count)) / (var(--count) + 0.5)),
        220px
    );
    /* финальный размер карточки */
    --size: var(--base-size);
    --rows: v-bind(props.rows);
    --gap: 8px;

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: var(--size);
    grid-template-rows: repeat(var(--rows), 1fr);
    gap: var(--gap);
    --cards-gap: 8; /* unitless, consumed by GameCard component */
}

.scroller::-webkit-scrollbar {
  display: none;
}

.scroller_empty {
    overflow: hidden;
}

/* Custom widths for specific number of visible cards, considers layout columns jumps */

.scroller {
    --count: 2;
}
@media (width >= 590px) {
    .scroller {
        --count: 3;
    }
}
@media (width >= 819px) {
    .scroller {
        --count: 4;
    }
}
@media (width >= 1381px) {
  .scroller {
    --count: 5;
  }
}
@media (width >= 2097px) {
    .scroller {
        --count: 6;
    }
}
@media (width >= 2440px) {
  .scroller {
    --count: 7;
  }
}

.card {
    scroll-snap-align: start;
    scroll-snap-stop: normal;
}

.card_big {
    grid-column: span 2;
    grid-row: span var(--rows);
}

/* make heavier selector to override button's styles, TODO refactor */
.scroller .card_next {
    height: auto;
    --border-radius: 12px;
    grid-row: span var(--rows);
}
</style>
