<template>
    <Transition
        :enter-from-class="$style.transition_enter_from"
        :enter-active-class="$style.transition_enter_active"
        :leave-active-class="$style.transition_leave_active"
        :leave-to-class="$style.transition_leave_to"
        @before-enter="onBeforeEnter"
        @after-leave="onAfterLeave"
        appear
    >
        <dialog
            v-if="categoriesModalOpened"
            :ref="dialogOnMount"
            @cancel="requestCloseModal"
            :class="$style.modal"
        >
            <div :class="$style.mobile_header">
                <MobileHeader categoriesModalOpened/>
            </div>
            <div :class="$style.modalBody">
                <Button @click="requestCloseModal" icon="close" color="carbon-400" :class="$style.close"/>
                <Button :link="DEVELOPERS_LANDPAGE_PATH" target="_blank" iconLeft="plus" :class="$style.add_game">
                    {{t('add_game')}}
                </Button>
                <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
                <div :class="$style.scroller" @click="outsideClick" >
                    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
                    <div :class="$style.grid_wrap" @click="outsideClick">
                        <div :class="$style.item_dynamic_hover" ref="itemDynamicHover"/>
                        <div :class="$style.grid">
                            <BaseLink
                                :to="'/shop'"
                                :class="$style.item"
                                @click="clickLink"
                                @mouseenter="itemMouseEnterOrFocus"
                                @mousemove="itemMouseMove"
                                @focus="itemMouseEnterOrFocus"
                            >
                                <Icon name="shop" :class="$style.item_icon"/>
                                <Typography type="label" size="m" :accent="true" :responsive="false">
                                    {{ t('shop') }}
                                </Typography>
                            </BaseLink>
                            <template v-for="(item, index) in menuHighlightCategoriesList" :key="item.id">
                                <BaseLink v-if="index === 2"
                                          :to="`/game/${randomGame?.hru}`"
                                          :class="$style.item"
                                          @click="clickRandomGame"
                                          @mouseenter="itemMouseEnterOrFocus"
                                          @mousemove="itemMouseMove"
                                          @focus="itemMouseEnterOrFocus"
                                >
                                    <Icon name="random" :class="$style.item_icon"/>
                                    <Typography type="label" size="m" :accent="true" :responsive="false">
                                        {{ t('random') }}
                                    </Typography>
                                </BaseLink>
                                <BaseLink
                                    :to="item.href"
                                    :class="$style.item"
                                    @click="clickLink"
                                    @mouseenter="itemMouseEnterOrFocus"
                                    @mousemove="itemMouseMove"
                                    @focus="itemMouseEnterOrFocus"
                                >
                                    <Icon :name="category2icon[item.name]" :class="$style.item_icon"/>
                                    <Typography type="label" size="m" :accent="true" :responsive="false">
                                        {{ t(item.name) }}
                                    </Typography>
                                </BaseLink>
                            </template>
                            <BaseLink
                                :to="DEVELOPERS_LANDPAGE_PATH"
                                target="_blank"
                                :class="[$style.item, $style.mobile]"
                                @mouseenter="itemMouseEnterOrFocus"
                                @mousemove="itemMouseMove"
                                @focus="itemMouseEnterOrFocus"
                            >
                                <Icon name="plus" :class="$style.item_icon"/>
                                <Typography type="label" size="m" accent :responsive="false">
                                    {{ t('add_game') }}
                                </Typography>
                            </BaseLink>
                        </div>
                        <div :class="$style.sep"></div>
                        <div :class="$style.grid">
                            <template v-for="(item) in menuCategoriesList" :key="item.id">
                                <BaseLink
                                    :to="item.href"
                                    :class="$style.item"
                                    @click="clickLink"
                                    @mouseenter="itemMouseEnterOrFocus"
                                    @mousemove="itemMouseMove"
                                    @focus="itemMouseEnterOrFocus"
                                >
                                    <Icon :name="category2icon[item.name]" :class="$style.item_icon"/>
                                    <Typography type="label" size="m" :accent="true" :responsive="false">
                                        {{ t(item.name) }}
                                    </Typography>
                                </BaseLink>
                            </template>
                        </div>
                        <div :class="[$style.sep, $style.non_desktop]"></div>
                        <div :class="[$style.grid, $style.non_desktop]">
                            <template v-for="(item, index) in NAVIGATION_LINKS" :key="index">
                                <BaseLink
                                    :to="item.link"
                                    :target="item.target"
                                    :class="$style.item"
                                    @click="clickLink"
                                    @mouseenter="itemMouseEnterOrFocus"
                                    @mousemove="itemMouseMove"
                                    @focus="itemMouseEnterOrFocus"
                                >
                                    <Typography type="label" size="m" :accent="true" :responsive="false">
                                        {{ t(item.title) }}
                                    </Typography>
                                </BaseLink>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </Transition>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import Button from '@/components_new/Button.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import MobileHeader from '@/components_new/MobileHeader.vue'
import { category2icon } from '@/components_new/Icon/categories'
import { useCategoriesStore } from '@/store/categories-store'
import { useLayoutStore } from '@/store/layout-store'
import { useLoggerStore } from '@/store/logger-store'
import { categoriesTranslation } from '@/utils/translations/categories'
import Typography from '@/components_new/Typography.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import { commonNavigationTranslation } from '@/utils/translations/common-navigation'
import { DEVELOPERS_LANDPAGE_PATH, NAVIGATION_LINKS } from '@/constants/general'
import { useDynamicHover } from './dynamicHover'

const { t } = useI18n({
    messages: {
        en: {
            ...categoriesTranslation.en,
            ...commonNavigationTranslation.en,
            shop: 'Shop',
            add_game: 'Add game',
        },
    },
})

const layoutStore = useLayoutStore()
const loggerStore = useLoggerStore()
const { setCategoriesModalOpened } = layoutStore

const { categoriesModalOpened } = storeToRefs(layoutStore)

const categoriesStore = useCategoriesStore()

const {
    menuCategoriesList,
    menuHighlightCategoriesList,
    randomGame,
} = storeToRefs(categoriesStore)

const dialogOnMount = (el: unknown | null) => (el as HTMLDialogElement)?.showModal()

// trigger exit animation
function requestCloseModal(e?: Event) {
    e?.preventDefault()
    setCategoriesModalOpened(false)
}

const scrollTop = ref(0)
function onBeforeEnter() {
    scrollTop.value = document.documentElement.scrollTop
    requestAnimationFrame(() => {
        document.body.scrollTop = scrollTop.value
        // ios safari has bug with bottom navigation pannel.
        // Sometimes it causes dialog element
        // to be shifted up after applying "overflow: hidden" on body.
        // Solution: set random positive scroll after repaint
        document.documentElement.scrollTop = 1
    })
}

function onAfterLeave() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = scrollTop.value
}

function clickLink() {
    loggerStore.logEvent({
        event: 'custom_event',
        eventName: 'navigate',
        label: 'categories_modal',
        action: 'click',
    })
    requestCloseModal()
}

function clickRandomGame() {
    categoriesStore.updateRandomGame()
    clickLink()
}

function outsideClick(e: MouseEvent) {
    if (e.target === e.currentTarget) {
        requestCloseModal(e)
    }
}

const {
    itemDynamicHover,
    updateItemDynamicHoverPositionDebounced,
    updateItemDynamicHoverPosition,
} = useDynamicHover()

function itemMouseEnterOrFocus(e: (MouseEvent | KeyboardEvent) & { currentTarget: HTMLElement }) {
    const { currentTarget } = e
    if ('pageX' in e) {
        updateItemDynamicHoverPositionDebounced({
            pageX: e.pageX,
            pageY: e.pageY,
            currentTarget,
        })
    } else {
        updateItemDynamicHoverPositionDebounced({ currentTarget })
    }
}

function itemMouseMove(e: MouseEvent & { currentTarget: HTMLElement }) {
    const { currentTarget } = e
    updateItemDynamicHoverPosition({ pageX: e.pageX, pageY: e.pageY, currentTarget })
}
</script>
<style module>
.modal {
    max-width: 100vw;
    max-height: 100dvh;
    width: 100vw;
    top: 0;
    height: 100dvh;
    overflow: hidden;
}

.modal::backdrop {
    display: none;
}

.mobile_header {
    display: none;
}

.close {
    position: absolute;
    top: 12px;
    left: 12px;
}

.add_game {
  position: absolute;
  top: 12px;
  right: 12px;
}

.scroller {
    height: 100%;
    padding: 24px 16px;
    box-sizing: border-box;
    overflow-x: clip;
    overflow-y: auto;
}

.grid_wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    gap: 24px;
    position: relative;
}

.grid {
    display: grid;
    width: 100%;
    max-width: 1036px;
    gap: 0 16px;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
}

@media (--mobile) {
    .modal[open] {
        display: flex;
        flex-direction: column;
        background: #000000;
    }

    .mobile_header {
        display: block;
        height: calc(var(--global-header-pannel-height) + env(safe-area-inset-top));
        flex-shrink: 0;
    }

    .modalBody {
        overflow: hidden;
    }

    .transition_enter_from.modal,
    .transition_leave_to.modal
    {
        opacity: 0;
    }

    .transition_enter_active.modal,
    .transition_leave_active.modal {
        pointer-events: none;
        transition: all .3s ease;
    }

    .close,
    .add_game {
        display: none;
    }

    .grid_wrap {
        align-items: flex-start;
    }
}

.item {
  padding: 16px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background-color: transparent;
  transition: background-color .1s;
  z-index: 0;
  outline: none;
}

@media (hover: none) {
  .item:active {
    background-color: #9747FF;
  }
}

.item_icon {
  width: 28px;
  height: 28px;
}

.item_dynamic_hover {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
  background-color: #9747FF;
  width: 30px;
  height: 30px;
  z-index: 0;

  visibility: hidden;
  opacity: 0;
  transition: opacity .1s ease, /* transform .1s ease, */ visibility 0s ease .1s;
}

.grid_wrap:hover .item_dynamic_hover,
.grid_wrap:focus-within .item_dynamic_hover {
  visibility: visible;
  opacity: 1;
  transition: opacity .1s ease, /* transform .1s ease, */ visibility 0s ease;
}

.sep {
  display: none;
}

@media (--tablet-plus) {
    .mobile {
      display: none;
    }
    .sep {
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.12);
      display: block;
    }
    .modalBody {
        height: 100%;
        background-color: rgba(0, 0, 0, .84);
        backdrop-filter: blur(20px);
    }

    .scroller {
        padding: 48px 16px;
    }

    .transition_enter_from.modal,
    .transition_leave_to.modal
    {
        opacity: 0;
        transform: scale(1.15);
        filter: blur(20px);
    }

    .transition_enter_active.modal,
    .transition_leave_active.modal {
        pointer-events: none;
        transition: all .3s cubic-bezier(.4,0,.2,1);
    }

    .grid_wrap {
        width: 80%;
        margin: auto;
    }
}

@media (--desktop-small-plus) {
  .non_desktop {
    display: none;
  }
}
</style>
