import { defineStore } from 'pinia'
import { PromoActions } from '@/components_new/PromoItems/types'
import { useLayoutStore } from '@/store/layout-store'
import { useLoggerStore } from '@/store/logger-store'
import { ref } from 'vue'

const addGameBannerClosedInMs = Number(process.env.VUE_APP_ADD_GAME_BANNER_CLOSED_MS) || 60 * 24 * 60 * 60 * 1000 // 60 days
export const usePromoStore = defineStore('promo', () => {
    const lastClosedAddGameBannerTimestamp = ref<number | null>(null)
    const shouldShowAddGameBanner = ref<boolean | null>(null)
    const { setInviteModalOpened, setSignInModalOpened } = useLayoutStore()
    const loggerStore = useLoggerStore()

    function setPromoAction(action: PromoActions) {
        if (action === 'sign_in') {
            setSignInModalOpened(true)

            loggerStore.logEvent({
                event: 'custom_event',
                eventName: 'sign-in',
                label: 'promo',
                action: 'click',
            })
        }
        if (action === 'invite') {
            setInviteModalOpened(true)

            loggerStore.logEvent({
                event: 'custom_event',
                eventName: 'invite-friend',
                label: 'promo',
                action: 'click',
            })
        }
    }

    function checkAddGameBannerVisibility() {
        if (typeof shouldShowAddGameBanner.value === 'boolean') {
            return
        }
        if (lastClosedAddGameBannerTimestamp.value) {
            const now = Date.now()
            if ((now - lastClosedAddGameBannerTimestamp.value) < addGameBannerClosedInMs) {
                shouldShowAddGameBanner.value = false
                return
            }
        }
        shouldShowAddGameBanner.value = true
        lastClosedAddGameBannerTimestamp.value = null
    }

    function hideAddGameBanner() {
        shouldShowAddGameBanner.value = false
        lastClosedAddGameBannerTimestamp.value = Date.now()
    }

    return {
        setPromoAction,
        hideAddGameBanner,
        shouldShowAddGameBanner,
        lastClosedAddGameBannerTimestamp,
        checkAddGameBannerVisibility,
    }
}, { persist: { pick: ['lastClosedAddGameBannerTimestamp'] } })
