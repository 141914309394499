export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "invitedBy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "<br>invites you to join Playgama"])},
        "invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were invited to join Playgama"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Playgama to grab your first 50 000 coins and start playing to earn real rewards!"])}
      }
    }
  })
}
