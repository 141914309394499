<template>
    <img
        ref="imageRef"
        :class="[$style.img, { [$style.transition]: hasTransition, [$style.loaded]: isLoaded }]"
        :src="src"
        :alt="alt"
        itemprop="image"
        :loading="loading || 'lazy'"
        @load="isLoaded = true"
    />
</template>
<script setup lang="ts">
import {
    ImgHTMLAttributes, onMounted, ref, watch,
} from 'vue'

const props = defineProps<{
    src: string
    alt: string
    loading?: ImgHTMLAttributes['loading']
    withFadeInOnLoad?: boolean
}>()

const emit = defineEmits<{
    loaded: []
}>()

const imageRef = ref<HTMLImageElement>()
const isLoaded = ref(false)
const hasTransition = ref(props.withFadeInOnLoad)

const removeOpacityTransition = (e: HTMLElementEventMap['transitionend']) => {
    if (e.propertyName !== 'opacity') {
        return
    }
    hasTransition.value = false
    imageRef.value?.removeEventListener('transitionend', removeOpacityTransition)
}

watch(isLoaded, (newValue) => {
    if (newValue) {
        emit('loaded')
    }
})
onMounted(() => {
    if (hasTransition.value) {
        imageRef.value?.addEventListener('transitionend', removeOpacityTransition)
    }
})
</script>

<style module>
.img.transition {
    opacity: 0;
    transition: opacity 0.3s ease-in;
    will-change: opacity;
}

.loaded.transition {
    opacity: 1;
}

.transition {
    transition: opacity 0.3s ease-in;
    will-change: opacity;
}
</style>
