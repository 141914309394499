<template>
    <component
        :is="is"
        :class="$style.item"
        :to="to"
        :target="target"
    >
        <Icon
            v-if="iconName"
            :class="$style.highlighted"
            :name="iconName"
        />
        <div :class="$style.column">
            <Typography
                size="s"
                :class="$style.title"
                v-if="slots.title"
            >
                <slot name="title" />
            </Typography>
            <Typography
                size="m"
                :class="$style.highlighted"
                v-if="slots.data"
            >
                <slot name="data" />
            </Typography>
        </div>
    </component>
</template>

<script lang="ts" setup>
import { IconNameType } from '@/components_new/Icon'
import Icon from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import { AnyComponent } from '@/types/vue.types'
import { useSlots } from 'vue'
import { RouteLocationRaw } from 'vue-router'

defineProps<{
    is: keyof HTMLElementTagNameMap | AnyComponent
    iconName?: IconNameType
    to?: string | RouteLocationRaw
    target?: '_self' | '_blank'
}>()

const slots = useSlots()
</script>

<style module>
.item {
    --transition-duration: 0.15s;

    display: flex;
    flex-direction: row;
    gap: var(--gap-medium);
    align-items: center;
    padding: var(--gap-small) 0;
}

.title {
    color: var(--new-white-40);
    transition: color var(--transition-duration) ease-out;
}

.item:hover .title {
    color: var(--white);
}

.highlighted {
    color: var(--new-purple-600);
    transition: color var(--transition-duration) ease-out;
}

.item:hover .highlighted,
.item:focus-visible .highlighted {
    color: var(--new-electric-lime);
}

.column {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xsmall);
    justify-content: center;
}
</style>
