import { FormSubmitData } from '@/types'
import { submitContactForm } from '@/utils/requests/form'
import { RequestStatus } from '@/utils/requests/types'
import { useIsMounted } from '@/utils/vue-hooks/use-is-mounted'
import { Ref, ref } from 'vue'

type Params = {
  additionalRequestParamsRef?: Ref<Pick<FormSubmitData, 'page'>>;
  onSuccessAnimationFinished?: () => void;
};

const getDefaultFormState = () => ({
    email: '',
    message: '',
})

export const useContactForm = ({ additionalRequestParamsRef, onSuccessAnimationFinished }: Params = {}) => {
    const submitStatus = ref<RequestStatus>('none')
    const formData = ref<FormSubmitData>(getDefaultFormState())
    const timerId = ref<ReturnType<typeof setTimeout>>()
    const isMounted = useIsMounted()

    const resetForm = () => {
        submitStatus.value = 'none'
        formData.value = getDefaultFormState()
    }

    // abstraction around setTimeout (resets the prev timer + returns a new promise to wait for, all-in-one call)
    const wait = (timeoutMs: number) => new Promise<void>((r) => {
        clearTimeout(timerId.value)
        timerId.value = setTimeout(r, timeoutMs)
    })

    const clearSubmitStatusWithTimeout = async ({ wasSuccessful = false, timeoutMs = 1000 } = {}) => {
        // show success/error state for timeoutMs duration
        await wait(timeoutMs)
        if (!isMounted.value) {
            return
        }
        // if user has not closed the modal already, reset state, close it
        submitStatus.value = 'none'
        if (wasSuccessful) {
            resetForm()
            onSuccessAnimationFinished?.()
        }
    }

    const handleSubmit = async (formData: FormSubmitData) => {
        if (submitStatus.value === 'pending') {
            return
        }
        submitStatus.value = 'pending'
        try {
            await submitContactForm({
                ...formData,
                ...additionalRequestParamsRef?.value,
            })
            submitStatus.value = 'ok'
            clearSubmitStatusWithTimeout({ wasSuccessful: true })
        } catch (e) {
            submitStatus.value = 'error'
            clearSubmitStatusWithTimeout({ timeoutMs: 1500 })
        }
    }

    return {
        submitStatus,
        handleSubmit,
        resetForm,
        formData,
    }
}
