export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "metaTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Contact Us"]), _normalize(["Playgama"])])},
        "metaDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch with the Playgama team for any inquiries, support, or feedback. We're here to help!"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask a question"])},
        "ctaSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide as much information as possible"])}
      }
    }
  })
}
