<template>
    <div :class="$style.promo_wrapper">
        <Button @click="emit('closePromo')" size="s" visual="text" icon="close" color="graphite" :class="$style.close" v-if="props.hasCloseBtn" />
        <component :is="props.link ? BaseLink : 'div'" :to="props.link?.to" :target="props.link?.target" :class="[$style.promo, $style[props.direction]]">
            <div :class="$style.promo_content">
                <img :class="$style.promo_image" :src="props.image.src" :alt="alt" v-if="props.image" />
                <div :class="[$style.promo_text, $style[props.textAlign]]">
                    <div :class="$style.promo_title">
                        <Icon :name="props.icon" v-if="props.icon"/>
                        <Typography type="header" size="s" :responsive="false" accent >{{props.title}}</Typography>
                    </div>
                    <Typography size="s" :responsive="false" :class="$style.promo_description">{{props.description}}</Typography>
                </div>
            </div>
            <div :class="$style[`promo_button_${props.buttonPosition}`]" v-if="$slots.default">
                <slot :handleClick="onClick"></slot>
            </div>
        </component>
    </div>
</template>
<script setup lang="ts">

import { computed, onBeforeMount } from 'vue'
import Icon, { IconNameType } from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import { PromoActions } from '@/components_new/PromoItems/types'
import { usePromoStore } from '@/components_new/PromoItems/store'
import BaseLink from '@/components_new/BaseLink.vue'
import Button from '@/components_new/Button.vue'

interface PromoBannerProps {
  title: string
  description: string,
  direction: 'row' | 'column'
  buttonPosition?: 'bottom' | 'right'
  textAlign?: 'left' | 'center'
  icon?: IconNameType
  hasCloseBtn?: boolean
  link?: { to: string, target: '_blank' | '_self' }
  image?: {src: string, alt: string}
}

/* eslint-disable-next-line no-unused-vars */
const emit = defineEmits<{'activatePromo': [], 'closePromo': [] }>()

const props = withDefaults(defineProps<PromoBannerProps>(), {
    buttonPosition: 'bottom',
    textAlign: 'center',
})
const alt = computed(() => {
    if (!props.image) {
        return ''
    }
    if (props.image.src && !props.image.alt) {
        throw Error('Promo Banner alt for image is required')
    }

    return props.image.alt
})

const promoStore = usePromoStore()

function onClick(action: PromoActions) {
    promoStore.setPromoAction(action)
    emit('activatePromo')
}

onBeforeMount(() => {
    promoStore.checkAddGameBannerVisibility()
})

</script>
<style module>
.promo_wrapper {
  position: relative;
}
.promo {
  display: flex;
  padding: 16px 12px;
  align-items: center;
  gap: 16px;
  background-color: #17171A;
  border-radius: 16px;
}

.promo.column {
  flex-direction: column;
  justify-content: center;
}

.promo.row {
  flex-direction: row;
  justify-content: space-between;
}

.promo.row:has(.promo_button_bottom) {
  flex-wrap: wrap;
}

.promo_image {
  width: var(--promo-image-width, 80px);
  height: var(--promo-image-height, 80px);
}
.promo_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}

.row .promo_content {
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}

.promo.row:has(.promo_button_right) {
  padding: 16px 24px;
}

.promo.row:has(.promo_button_right) .promo_content {
  gap: 24px;
}

.promo_text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.promo_text.center {
  text-align: center;
  align-items: center;
}
.promo_text.left {
  text-align: left;
  align-items: flex-start;
}

.row .promo_text {
  text-align: left;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
}

.promo_text.center .promo_description {
  padding: 0 10px;
}

.promo_description {
  color: rgba(255, 255, 255, 0.6);
}

.promo_button_bottom,
.promo_button_bottom > * {
  width: 100%;
}

.promo_title {
  display: flex;
  gap: 6px;
  align-items: center;
}

.close {
  position: absolute;
  top: 4px;
  right: 4px;
}

</style>
