import { onMounted, onUnmounted, ref } from 'vue'

/**
 * Allows us to check isMounted to avoid memory leaks
 * before state updates after async operations (React memories...)
 * Sometimes useful for Vue teleports (but mostly fixed in newer Vue versions)
 */
export const useIsMounted = () => {
    const isMounted = ref(false)
    onMounted(() => {
        isMounted.value = true
    })
    onUnmounted(() => {
        isMounted.value = false
    })

    return isMounted
}
