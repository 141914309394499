import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia()
// in ssr catch error if plugin doesn't get window object
if (typeof window !== 'undefined') {
    pinia.use(piniaPluginPersistedstate)
}

export default pinia
