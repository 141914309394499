<template>
    <BaseLink
        :to="props.to"
        :href="props.to"
        :target="props.target"
        :class="[
            $style.link,
            $style[`color_${props.color}`],
            props.disabled ? $style.disabled : '',
        ]"
        :disabled="props.disabled"
        @click="emit('click', $event)"
    >
        <slot />
    </BaseLink>
</template>

<script lang="ts" setup>
import BaseLink from '@/components_new/BaseLink.vue'
import { RouteLocationRaw } from 'vue-router'

export interface LinkProps {
    disabled?: boolean,
    to: string | RouteLocationRaw,
    target?: '_self' | '_blank',
    color: 'purple-500' | 'purple-600' | 'golden' | 'white' |'white-60' | 'white-80'
}

const props = defineProps<LinkProps>()

/* eslint-disable-next-line no-unused-vars */
const emit = defineEmits<{(e: 'click', value: Event): void}>()

</script>

<style module>
/* Color themes */

.color_purple-500 {
    --text: #AA76FF;
    --text-hover: #C0FF47;
}

.color_purple-600 {
  --text: #C7ABFF;
  --text-hover: #C0FF47;
}

.color_golden {
    --text: #FFDCA1;
    --text-hover: #FFC850;
}

.color_white {
    --text: #FFF;
    --text-hover: #E6D4FF;
}

.color_white-80 {
    --text: rgba(255, 255, 255, 0.80);
    --text-hover: #E6D4FF;
}

.color_white-60 {
  --text: rgba(255, 255, 255, 0.60);
  --text-hover: #E6D4FF;
}

.link {
    color: var(--text);
}

.link:hover,
.link:focus-visible,
.link:has(:focus-visible),
.link:active {
  color: var(--text-hover);
}

.link:focus-visible {
  color: var(--text-hover);
  outline: none;
}

.link.disabled,
.link.disabled:hover,
.link.disabled:active,
.link.disabled:focus-visible,
.link.disabled:has(:focus-visible) {
  color: var(--text);
  cursor: default;
}

</style>
