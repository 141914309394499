<template>
    <Layout>
        <main :class="$style.page">
            <section :class="$style.title_page">
                <Typography is="h1" type="header" size="m" :responsive="false">{{t('page_title')}}</Typography>
                <Button color="carbon-400" visual="text" size="m" iconRight="logout" @click="logout">
                    {{t('logout')}}
                </Button>
            </section>
            <template v-if="isUserInfoLoaded">
                <section :class="$style.balance">
                    <div :class="$style.user_balance">
                        <Icon name="fennek_coin_large" :class="$style.user_balance__coins"/>
                        <div :class="$style.user_balance__text">
                            <Typography size="s" :responsive="false" :class="$style.user_balance__label" accent>
                                {{ t('balance_label') }}
                            </Typography>
                            <!-- eslint-disable-next-line vuejs-accessibility/heading-has-content -->
                            <Typography is="h2" type="header" size="xxxl" :responsive="false">
                                <Balance :balance="userBalance" />
                            </Typography>
                        </div>
                    </div>
                    <PromoInviteHint :label="t('invite_friend_mobile_label')" :class="$style.promo_mobile"/>
                    <PromoInviteHint :class="$style.promo_desktop"/>
                </section>
                <div :class="$style.hr"/>
                <Tasks>
                    <TasksClaimedMessage/>
                </Tasks>
                <div :class="$style.hr"/>
            </template>
            <DropCards :class="$style.drop_cards"/>
            <PromoInviteVertical  :class="$style.promo_mobile"/>
            <PromoInviteHorizontal  :class="$style.promo_tablet"/>
            <ShopCards />
        </main>
    </Layout>
</template>
<script lang="ts" setup>
import { onBeforeMount, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import { useCategoriesStore } from '@/store/categories-store'
import Tasks from '@/components_new/TaskItems/Tasks.vue'
import Typography from '@/components_new/Typography.vue'
import { useUserStore } from '@/store/user-store/user-store'
import { useLoggerStore } from '@/store/logger-store'
import Button from '@/components_new/Button.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import { useRouter } from 'vue-router'
import ShopCards from '@/components_new/ShopCards/ShopCards.vue'
import PromoInviteHint from '@/components_new/PromoItems/components/PromoInviteHint.vue'
import PromoInviteVertical from '@/components_new/PromoItems/components/PromoInviteVertical.vue'
import DropCards from '@/components_new/DropCards/DropCards.vue'
import PromoInviteHorizontal from '@/components_new/PromoItems/components/PromoInviteHorizontal.vue'
import Balance from '@/components_new/Balance.vue'
import TasksClaimedMessage from '@/components_new/TasksClaimedMessage.vue'

const userStore = useUserStore()
const { userBalance, isUserInfoLoaded } = storeToRefs(userStore)
const categoriesStore = useCategoriesStore()
const router = useRouter()
const loggerStore = useLoggerStore()
const { t } = useI18n()

function logout() {
    loggerStore.logEvent({
        event: 'custom_event',
        eventName: 'sign-out',
        label: 'profile',
        action: 'click',
    })
    userStore.logout()
    router.replace('/')
}

onMounted(async () => {
    await categoriesStore.setMainData()
})

onBeforeMount(async () => {
    const user = await userStore.getUser()
    if (!user) {
        await router.replace('/shop')
    }
})

</script>
<style module>
.page {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.title_page {
  display: none;
}

.balance {
  display: flex;
  gap: 12px;
}

.user_balance {
  flex-grow: 1;
  display: flex;
  gap: 12px;
  border: none;
}

.user_balance__coins {
  width: 88px;
  height: 88px;
}

.user_balance__text {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.user_balance__label {
  color: rgba(255, 255, 255, 0.80)
}

.hr {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.drop_cards {
  margin: 16px 0;
}

.promo_tablet {
  display: none;
}

.promo_desktop {
  display: none;
}

.promo_mobile {
  display: block;
}

@media (--mobile) {
  .balance {
    flex-direction: column;
    gap: 24px;
  }

  .user_balance {
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }

  .hr {
    border: none;
  }
}

@media (--tablet-plus) {
  .balance {
    justify-content: space-between;
    align-items: center;
  }

  .user_balance {
    border: none;
  }

  .promo_mobile {
    display: none;
  }

  .promo_tablet,
  .promo_desktop {
    display: block;
  }
}

@media (--desktop-small-plus) {
  .title_page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }

  .promo_tablet {
    display: none;
  }
}

</style>
<i18n lang="json">
{
  "en": {
    "page_title": "Game room",
    "logout": "Logout",
    "balance_label": "Balance",
    "invite_friend_mobile_label": "50 000 for a friend"
  }
}
</i18n>
