import { Banner } from './banner'
import type { RewardedBanner } from './rewarded-banner'

export type BannerEventType = 'closed' | 'rewarded' | 'empty' | 'ready' | 'rendered' | 'viewable'

export type BannerEventListenerArgs = [
    type: BannerEventType,
    callback: (event: Event) => any,
]

export type PageAdType =
    | 'sidebar'
    | 'sticky_portrait'
    | 'leaderboard'
    | 'leaderboard_rest'
    | 'catalog'
    | 'catalog_mobile'
    | 'catalog_mobile_rest'

export type OutOfPageAdType =
    | 'fullscreen'
    | 'interstitial'
    | 'rewarded'
    | 'sticky_portrait' // might be static and out-of-page

export enum AdProviderStatus {
    loading= 'loading',
    online = 'online',
    offline = 'offline'
}

export enum AdvAction {
    preloadRewarded = 'preloadRewarded',
    showRewarded = 'showRewarded',
    preloadInterstitial = 'preloadInterstitial',
    showInterstitial = 'showInterstitial',
}

export type PageAdSize = 'fluid' | [number, number]

export type PageAdOptions = {
    el?: string, // id of element where to mount the ad
    sizes?: PageAdSize[],
    type: PageAdType,
    refresh?: number // seconds
    clid?: string,
    gameId?: string,
    userId?: string,
}

export type ManualAdOptions = Omit<PageAdOptions, 'type'> & {
    type: PageAdType | OutOfPageAdType
}

export type AdvServiceConfig = {
    targeting?: Record<string, string | string[]>
}
export abstract class AdvService {
    abstract readonly serviceStatus: Promise<AdProviderStatus>

    abstract prepareOutOfPageAd(type: OutOfPageAdType, timeout?: number): Promise<RewardedBanner>

    abstract requestPageAd(options: PageAdOptions): Promise<Banner>

    abstract updateTargeting(targeting: AdvServiceConfig['targeting']): void
}
