export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram"])},
        "discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
        "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
        "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
        "developers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developers"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
        "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])},
        "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
        "playgamaForDevs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playgama for Devs"])}
      }
    }
  })
}
