<template>
    <div :class="$style.container">
        <Typography is="ul" type="label" :accent="true" size="s" :class="$style.breadcrumbs" :responsive="false" itemscope itemtype="https://schema.org/BreadcrumbList">
            <li v-for="(item, index) in breadcrumbs" :key="item.title"
                itemprop="itemListElement"
                itemscope
                itemtype="https://schema.org/ListItem">
                <span v-if="index !== 0" :class="$style.breadcrum_item"  >
                    /
                </span>
                <Link
                    itemprop="item"
                    :disabled="item.disabled"
                    color="white-60"
                    :to="item.link"
                    :class="$style.breadcrum_item"
                    type="label"
                >
                    <span itemprop="name">{{ item.title }}</span>
                    <meta itemprop="position" :content="index + 1" />
                </Link>
            </li>
        </Typography>
        <Typography is="h1" size="m" >{{ props.category?.name }}</Typography>
        <Typography is="p" size="xs"  v-if="props.category?.description" :class="$style.description">
            {{ props.category?.description }}
        </Typography>
    </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { categoriesTranslation } from '@/utils/translations/categories'
import Typography from '@/components_new/Typography.vue'
import Link from '@/components_new/Link.vue'

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

const props = defineProps<{
    category: {
        id: string;
        name: string;
        description?: string;
    } | null;
}>()

const breadcrumbs = computed(() => ([
    {
        title: 'Playgama',
        link: '/',
    },
    {
        title: t(props.category?.id || ''),
        disabled: true,
        link: `/category/${props.category?.id}`,
    },
]))
</script>
<style module>
.container {
    display: flex;
    padding: 16px 16px 24px 16px;

    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;

    border-radius: 16px;
    background: #17171A;
}

.breadcrumbs {
    margin-bottom: 8px;

    color: rgba(255, 255, 255, 0.6);
}

.breadcrumbs li {
    display: contents;
}

.breadcrum_item {
    padding: 8px 2px;
}

.description {

    margin-top: 12px;
    color: rgba(255, 255, 255, 0.8);
}
</style>
