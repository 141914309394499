<template>
    <CardContainer :appearance="props.appearance"
                   :responsive="props.responsive"
                   :is="BaseLink"
                   target="_blank"
                   :to="DEVELOPERS_LANDPAGE_PATH"
                   :class="$style.card">
        <div :class="$style.card_content">
            <Icon name="plus" :class="$style.icon"></Icon>
            <Icon name="chevron_r" :class="$style.icon_active"></Icon>
            <Typography type="label" size="l" accent :responsive="false">
                {{t('title')}}
            </Typography>
            <Typography type="label" size="xs" :responsive="false" :class="$style.description">
                {{t('description')}}
            </Typography>
        </div>
    </CardContainer>
</template>

<script setup lang="ts">
/* eslint-disable */
import CardContainer from '@/components_new/CardContainer.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import { DEVELOPERS_LANDPAGE_PATH } from '@/constants/general'
import Icon from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface AddGamePromoCardProps {
  appearance: 'big'|'medium',
  responsive: boolean,
}

const props = defineProps<AddGamePromoCardProps>()

</script>

<style module>
.card {
  background-color: #9747FF;
  color: #ffffff;
  display: flex;
}

.card .description {
  color: rgba(255, 255, 255, 0.60);
}

.card:hover,
.card:active {
  background-color: #9CE52E;
  color: #000000;
}

.card:hover .description,
.card:active .description {
  color: rgba(0, 0, 0, 0.60)
}


.card_content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  justify-content: flex-end;
}

.icon_active,
.icon {
  align-self: flex-start;
  margin-bottom: auto;
  flex-shrink: 0;
}

.card .icon {
  display: block;
}

.card .icon_active {
  display: none;
}

.card:hover .icon,
.card:active .icon {
  display: none;
}

.card:hover .icon_active,
.card:active .icon_active {
  display: block;
}

</style>

<i18n lang="json">
{
  "en": {
    "title": "Your Game here",
    "description": "Submit. Reach. Enjoy."
  }
}
</i18n>
