<template>
    <div :class="$style.sidebar">
        <Logo :class="$style.logo" />
        <div :class="$style.sections">
            <template v-if="isUserInfoLoaded">
                <BaseLink
                    v-if="isAuthorized && user"
                    to="/profile"
                    :disabled="isUserProfilePage"
                    :class="$style.section"
                >
                    <UserAndBalance :user="user" :userBalance="userBalance" :isProfilePage="isUserProfilePage" />
                </BaseLink>
                <PromoSignInHorizontalShort/>
            </template>
            <TasksWidget v-if="!isUserProfilePage" showOnlyFirst :redirect="!isUserProfilePage">
                <PromoInviteVertical title="50 000 +"/>
            </TasksWidget>
            <PromoInviteVertical title="50 000 +" v-if="isUserProfilePage"/>
            <div :class="$style.section">
                <Typography is="ul" :class="$style.links" type="label" size="s" :accent="true" :responsive="false">
                    <li>
                        <Link to="" :color="'white-60'" :class="$style.links_item" @click="openCategories">
                            {{ t('btn_categories') }}
                        </Link>
                    </li>
                    <li>
                        <span :class="$style.inner_sep"></span>
                        <Link to="/shop" :disabled="isShop" :color="'white-60'" :class="$style.links_item">
                            {{ t('btn_shop') }}
                        </Link>
                    </li>
                    <li v-for="item in restMenuItems" :key="item.title">
                        <span :class="$style.inner_sep"></span>
                        <Link :target="item.target" :to="item.link" :color="item.color || 'white-60'" :class="$style.links_item">
                            <Icon :name="item.icon" v-if="item.icon" :class="$style.links_item_icon"/>
                            {{ t(item.title) }}
                        </Link>
                    </li>
                </Typography>
            </div>
        </div>
        <AdBlock
            type="sidebar"
            :refreshSec="30"
            :class="$style.ad"
        />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/store/user-store/user-store'
import { useLayoutStore } from '@/store/layout-store'
import Logo from '@/components_new/Logo/Logo.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import TasksWidget from '@/components_new/TaskItems/TasksWidget.vue'
import PromoSignInHorizontalShort from '@/components_new/PromoItems/components/PromoSignInHorizontalShort.vue'
import PromoInviteVertical from '@/components_new/PromoItems/components/PromoInviteVertical.vue'
import { DEVELOPERS_LANDPAGE_PATH } from '@/constants/general'
import Link, { LinkProps } from '@/components_new/Link.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import UserAndBalance from '@/components_new/UserAndBalance.vue'
import AdBlock from '@/components/AdBlock.vue'
import { commonNavigationTranslation } from '@/utils/translations/common-navigation'
import { IconNameType } from '@/components_new/Icon'

const { t } = useI18n({
    messages: {
        en: {
            ...commonNavigationTranslation.en,
            btn_shop: 'Shop',
            btn_categories: 'Games categories',
        },
    },
})

type MenuItem = {
    title: string,
    link: string,
    target?: '_self' | '_blank',
    icon?: IconNameType,
    color?: LinkProps['color'],
}
const restMenuItems: MenuItem[] = [
    {
        title: 'developers',
        link: DEVELOPERS_LANDPAGE_PATH,
        target: '_blank' as const, // Do not remove. This route is not in the Vue app
    },
    // Temporary disable to fit Ad, until better design
    // {
    //     title: 'advertisers',
    //     link: '/advertisers',
    // },
    // {
    //     title: 'publishers',
    //     link: '/publishers',
    // },
    // {
    //     title: 'We are hiring',
    //     icon: 'linked_in' as const,
    //     color: 'golden' as const,
    //     link: 'https://www.linkedin.com/company/playgama',
    //     target: '_blank' as const,
    // },
]

const { setCategoriesModalOpened } = useLayoutStore()

function openCategories() {
    setCategoriesModalOpened(true)
}

const userStore = useUserStore()
const route = useRoute()
const isUserProfilePage = computed(() => route.name === 'profile')
const isShop = computed(() => route.name === 'shop')

const {
    user, isAuthorized, userBalance, isUserInfoLoaded,
} = storeToRefs(userStore)

</script>

<style module>
.sidebar {
    display: flex;
    flex-direction: column;
}

.logo {
    margin: 0 auto;
    height: 88px;
    box-sizing: border-box;
    padding: 24px 0;
    --logo-width: 184px;
    --logo-height: auto;
}

.sections {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.section {
    border-radius: 16px;
    background: #17171a;
}

.links {
    padding: 4px 12px;
}

.links_item {
    display: flex;
    align-items: center;
    padding: 12px 0;
    gap: 8px;
}

.links_item_icon {
  width: 22px;
  height: 22px;
}

.inner_sep {
    height: 1px;
    display: block;
    background: rgba(255, 255, 255, 0.08);
}

.ad {
    margin-top: 12px;
}
</style>
