<template>
    <section :class="$style.section">
        <div :class="$style.content">
            <slot />
        </div>
    </section>
</template>
<script lang="ts" setup></script>

<style module>
.section {
    width: 100%;
    display: flex;
    justify-content: center;
}
.content {
    max-width: 1000px;
    flex-grow: 1;
}
</style>
