export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "fennek-butterfly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fennek playing with a butterfly"])},
        "person-in-a-flower-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person in a flower-field"])},
        "ping-pong-rocket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ping-pong rocket"])},
        "boring-docs-disk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk with caption: Boring docs"])},
        "gems-on-pillow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gems on top of the pillow"])},
        "halved-apple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halved apple"])}
      }
    }
  })
}
