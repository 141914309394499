export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us on Playgama"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play games, rack up coins, and score real-worlds rewards!"])},
        "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us on Playgama"])}
      }
    }
  })
}
