export const promoItemsTranslation: Record<string, Record<string, string>> = {
    en: {
        watch_ad_btn: 'Watch short Ad',
        watch_ad_alt: 'Watch short Ad',
        watch_ad_desc: 'Watch a short ad and get 5,000 coins',
        invite_btn: 'Invite friend',
        invite_alt: 'Invite friend',
        invite_desc: 'Get 50 000 coins for each friend you bring to Play and Earn',
        sign_in_btn: 'Login & Get',
        sign_in_alt: 'Login and Get coins',
        sign_in_desc: 'Sign up, play games, earn coins, and trade them for real-world rewards',
        sign_in_title: '10 000 coins',
        add_game_btn: 'Add game',
        add_game_alt: 'Add game',
        add_game_desc: 'Bring it to Playgama and let us help you connect with the audience it deserves!',
        add_game_title: 'Got a game?',
    },
}
