import { FormSubmitData } from '@/types'
import { request } from './request'

export function submitContactForm(data: FormSubmitData) {
    return request('/api/v1/email/report', {
        method: 'POST',
        cache: 'no-store',
        body: JSON.stringify(data),
    })
}
