export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
        "messageSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message was sent"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "agreementTextStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking \"Send\" I agree to the "])},
        "agreementTextAnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and the "])},
        "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])}
      }
    }
  })
}
