<template>
    <section>
        <Typography is="h2" type="header" :class="$style.drops_title" size="m">{{t('title')}}</Typography>
        <ul :class="$style.drops">
            <li v-for="drop in DROPS" :key="drop.id">
                <CardContainer :src="drop.src" appearance="big" :alt="drop.title" responsive/>
                <Typography size="m" :class="$style.drops__title">{{drop.title}}</Typography>
                <Button size="l" visual="outline" color="white" disabled> {{t('button_label') }}</Button>
            </li>
        </ul>
    </section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Typography from '@/components_new/Typography.vue'
import CardContainer from '@/components_new/CardContainer.vue'
import Button from '@/components_new/Button.vue'
import roboloxImg from '@/components_new/DropCards/images/roblox_gift_card.png'
import amazonCardImg from '@/components_new/DropCards/images/amazon_gift_card.png'

const { t } = useI18n()

const DROPS = [
    {
        title: 'Roblox Gift Cards',
        id: '1',
        src: roboloxImg,
    }, {
        title: 'Amazon Gift Cards',
        id: '2',
        src: amazonCardImg,
    }]
</script>

<style module>

.drops {
  display: flex;
  flex-direction: column;
  gap: 12px;
  --cards-gap: 0;
}

.drops li {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.drops__title {
  color: rgba(255, 255, 255, 0.80);
  margin-bottom: 16px;
}

.drops_title {
  margin-bottom: 16px;
}

@media (--tablet-plus), (--mobile-landscape) {
  .drops {
    flex-direction: row;
  }
}
</style>
<i18n lang="json">{
  "en": {
    "title": "Drops",
    "button_label": "Drop is over"
  }
}</i18n>
