<template>
    <div :class="[$props.class, $style.adContainer, !bannerVisible && props.emptyClass]" ref="containerRef">
        <div :class="$style.adBlockWrap">
            <Typography
                v-if="bannerVisible"
                type="caption_all-caps"
                :class="$style.adCaption"
            >
                {{ t('advertising') }}
            </Typography>
            <div :class="$style.adBlock" :id="id"/>
        </div>
    </div>
</template>
<script lang="ts" setup>
import {
    computed,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    watch,
} from 'vue'
import { useI18n } from 'vue-i18n'
import Typography from '@/components_new/Typography.vue'
import { useAdsStore } from '@/store/ads-store'
import { getRandomNumber } from '@/utils/helpers'
import type { Banner } from '@/modules/adv/banner'
import type { PageAdSize, PageAdType } from '@/modules/adv'
import { useResizeObserver, useIntersectionObserver, useDebounceFn } from '@vueuse/core'

const { t } = useI18n()

const adsStore = useAdsStore()

const defaultSlotSizes: Record<PageAdType, PageAdSize[]> = {
    sidebar: [[300, 250], [300, 300], 'fluid'],
    leaderboard: [[728, 90], [950, 90], [960, 90], [970, 90], [980, 90], 'fluid'],
    leaderboard_rest: [[728, 90], [950, 90], [960, 90], [970, 90], [980, 90], 'fluid'],
    catalog: [[728, 90], [970, 250], 'fluid'],
    catalog_mobile: [[300, 300], [336, 280], [300, 250], 'fluid'],
    catalog_mobile_rest: [[300, 300], [336, 280], [300, 250], 'fluid'],
    sticky_portrait: ['fluid'],
}

export type AdBlockProps = {
    type: PageAdType,
    sizes?: PageAdSize[],
    class?: string,
    /**
     * Class to add when the ad is not loaded
     * Should not hide element with "display: none", otherwise the ad will never be loaded
     */
    emptyClass?: string,
    intersectionRootMargin?: string,
    refreshSec?: number,
}

const props = defineProps<AdBlockProps>()

const sizes = computed(() => props.sizes || defaultSlotSizes[props.type])

// get sorted sizes in descending width order, "fliud" is always the last — it can be any size
const sortedSizesDesc = computed(() => sizes.value.slice().sort(
    (a, b) => {
        if (a === 'fluid') {
            return 1
        }
        if (b === 'fluid') {
            return -1
        }
        return b[0] - a[0]
    },
))

const banner = ref<Banner>()
const bannerEmpty = ref(true)
const bannerVisible = computed(() => !!banner.value && !bannerEmpty.value)

const id = ref('')
const containerRef = ref<HTMLElement | null>(null)
const lastMaxWidthIndex = ref(-1)
const readyForAdRequest = ref(props.intersectionRootMargin === undefined)

async function updateBanner() {
    if (!readyForAdRequest.value) {
        return
    }

    if (!adsStore.adMediator) {
        return
    }

    const container = containerRef.value
    if (!container) {
        return
    }

    const rect = container.getBoundingClientRect()

    // find the index of the largest size that fits the container
    const maxWidthIndex = sortedSizesDesc.value.findIndex((adSize) => {
        if (adSize === 'fluid') {
            return true
        }
        return rect.width >= adSize[0]
    })

    // do nothing, if the max size is the same
    if (maxWidthIndex === lastMaxWidthIndex.value) {
        return
    }

    lastMaxWidthIndex.value = maxWidthIndex

    // destroy the previous banner
    banner.value?.destroy()
    banner.value = undefined

    // prepare a new banner if there are sizes
    if (maxWidthIndex > -1 && rect.width > 0) {
        banner.value = await adsStore.adMediator?.prepareAd({
            type: props.type,
            refresh: props.refreshSec,
            el: id.value,
            sizes: sortedSizesDesc.value.slice(maxWidthIndex),
        })

        // these event listeners will be collected by GC when banner is destroyed/recreated
        banner.value?.addEventListener('rendered', () => { bannerEmpty.value = false })
        banner.value?.addEventListener('closed', () => { bannerEmpty.value = true })
        banner.value?.addEventListener('empty', () => { bannerEmpty.value = true })
    }
}
const updateBannerDebounced = useDebounceFn(updateBanner, 1000)

function checkEmptyStyles() {
    if (!props.emptyClass) {
        return
    }

    const elem = containerRef.value?.appendChild(document.createElement('div'))
    if (elem) {
        elem.classList.add(props.emptyClass)
        if (window.getComputedStyle(elem).display === 'none') {
            console.error(`AdBlock: class .${props.emptyClass} sets display to "none". That breaks tracking of the future size changes.\n\nUse negative margins to hide empty ad in the layout.`)
        }
        elem.remove()
    }
}

onBeforeMount(() => {
    id.value = `ad_${getRandomNumber(10000, 99999, 16)}`
})

onMounted(async () => {
    checkEmptyStyles()
})

watch(() => props.emptyClass, () => {
    checkEmptyStyles()
})

watch(() => props.sizes, () => {
    lastMaxWidthIndex.value = -1
    updateBanner()
})

useResizeObserver(containerRef, updateBannerDebounced)

if (props.intersectionRootMargin !== undefined) {
    const { stop } = useIntersectionObserver(containerRef, ([entry]) => {
        if (entry.isIntersecting) {
            readyForAdRequest.value = true
            updateBanner()
            stop()
        }
    }, {
        rootMargin: props.intersectionRootMargin,
    })
}

onBeforeUnmount(() => {
    banner.value?.destroy()
})

</script>

<style module>
.adContainer {}

.adBlockWrap {
    width: fit-content;
    margin: 0 auto;
}

/* fliud ads has inline style with "width: 100%". In that case adBlockWrap should also take all space */
.adBlockWrap:has(div[style*="width: 100%;"]) {
    width: 100%;
}

.adBlock {
    /* TODO: check ad requirements and remove if not needed */
    min-width: 1px;
    min-height: 1px;
    overflow: hidden;
}

.adCaption {
    display: block;
    margin-bottom: 4px;
    color: rgba(255, 255, 255, 0.6)
}

.adCaption:has(~ .adBlock:empty) {
    display: none;
}

</style>
<i18n lang="json">{
    "en": {
        "advertising": "Advertising"
    }
}</i18n>
