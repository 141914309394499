<template>
    <Layout>
        <main :class="$style.page">
            <PromoSignInHorizontal :class="$style.tablet"/>
            <PromoAddGameHorizontal :class="$style.desktop"/>
            <PromoSignInVertical :class="$style.mobile"/>
            <Tasks :class="$style.tablet"/>
            <Tasks :class="$style.mobile">
                <PromoInviteVertical />
            </Tasks>
            <template
                v-for="(category, index) in preparedCategories"
                :key="category.name"
            >
                <GameCardCarousel
                    :title="category.title"
                    :link="category.link"
                    :games="category.games"
                    :big="category.isBig"
                    :firstItemBig="category.firstItemBig"
                    :lastItemLink="category.link"
                    targetLinkGame="_blank"
                    :promo="category.name === 'new' ? {type: 'add_game'} : undefined"
                />
                <PromoInviteHorizontal  :class="$style.tablet" v-if="index === 0" />
                <template v-if="index === 1">
                    <AdBlock
                        type="leaderboard"
                        :class="$style.tabletPlus"
                        :emptyClass="$style.emptyRow"
                    />
                    <AdBlock
                        type="catalog_mobile"
                        :class="$style.mobile"
                        :emptyClass="$style.emptyRow"
                    />
                </template>
                <PromoAddGameHorizontalShort :class="$style.mobile" v-if="index === 2"/>
                <PromoAddGameHorizontal :class="$style.tablet" v-if="index === 4"/>
                <template v-if="index === 4">
                    <AdBlock
                        type="catalog"
                        :class="$style.tabletPlus"
                        :emptyClass="$style.emptyRow"
                    />
                    <AdBlock
                        type="catalog_mobile_rest"
                        :class="$style.mobile"
                        :emptyClass="$style.emptyRow"
                    />
                </template>
                <PromoCategories hasHeader v-if="index === 7" />
                <!-- every 3rd after 8th (index is zero based) -->
                <template v-if="index > 7 && (index - 7) % 3 === 0">
                    <AdBlock
                        type="leaderboard_rest"
                        :class="$style.tabletPlus"
                        :emptyClass="$style.emptyRow"
                        intersectionRootMargin="100px 0px"
                    />
                    <AdBlock
                        type="catalog_mobile_rest"
                        :class="$style.mobile"
                        :emptyClass="$style.emptyRow"
                        intersectionRootMargin="100px 0px"
                    />
                </template>
            </template>
            <TheEnd targetUrl="_blank"/>
        </main>
    </Layout>
</template>
<script lang="ts" setup>
import {
    onServerPrefetch,
    onMounted,
    computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useHead } from '@unhead/vue'
import { useCategoriesStore } from '@/store/categories-store'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import TheEnd from '@/components_new/TheEnd/TheEnd.vue'
import GameCardCarousel from '@/components_new/GameCardCarousel.vue'
import PromoCategories from '@/components_new/PromoCategories/PromoCategories.vue'
import { categoriesTranslation } from '@/utils/translations/categories'
import { CategoryListType } from '@/types'
import PromoSignInHorizontal from '@/components_new/PromoItems/components/PromoSignInHorizontal.vue'
import Tasks from '@/components_new/TaskItems/Tasks.vue'
import PromoInviteHorizontal from '@/components_new/PromoItems/components/PromoInviteHorizontal.vue'
import PromoSignInVertical from '@/components_new/PromoItems/components/PromoSignInVertical.vue'
import PromoInviteVertical from '@/components_new/PromoItems/components/PromoInviteVertical.vue'
import AdBlock from '@/components/AdBlock.vue'
import PromoAddGameHorizontal from '@/components_new/PromoItems/components/PromoAddGameHorizontal.vue'
import PromoAddGameHorizontalShort from '@/components_new/PromoItems/components/PromoAddGameHorizontalShort.vue'

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

const categoriesStore = useCategoriesStore()

const {
    contentCategoriesList,
} = storeToRefs(categoriesStore)

useHead({
    link: [
        {
            rel: 'canonical',
            href: process.env.VUE_APP_HOST_URL,
        },
    ],
})

function getTitle(categoryName: string) {
    return t(categoryName)
}

function getLink(categoryName: string) {
    return `/category/${categoryName}/`
}

function prepareCategories(categories: CategoryListType[]) {
    return categories.map((category, index) => {
        const isBig = index % 2 === 0 && index > 0
        const firstItemBig = index === 0

        let games = category.games?.slice(0)
        if (!isBig && games) {
            if (
                (firstItemBig && games.length % 2 === 0)
                || (!firstItemBig && games.length % 2 === 1)
            ) {
                games = games.slice(0, -1)
            }
        }
        return {
            name: category.name,
            title: getTitle(category.name),
            link: getLink(category.name),
            games,
            isBig,
            firstItemBig,
        }
    })
}

const preparedCategories = computed(() => prepareCategories(contentCategoriesList.value))

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
})

onMounted(async () => {
    await categoriesStore.setMainData()
})
</script>
<style module>
.page {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

/*
    Style for empty Ad block.
    Cannot not be just "display: none" because Ad container needs to be visible to react on size change.
*/
.emptyRow {
  margin-top: -24px;
}

.mobile, .tablet, .tabletPlus, .desktop {
  display: none;
}

@media (--mobile) {

  .mobile {
    display: block;
  }
}

@media (--tablet-plus) {

  .tablet, .tabletPlus {
    display: block;
  }
}

@media (--desktop-small-plus) {

  .tablet {
    display: none;
  }

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }
}
</style>
